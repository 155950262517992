@if $toc {
  /* - Datepicker_______________Calendar */
} @else {
  /* DatePicker [component (React)]
     ========================================================================== */

  .DayPicker {
    display: inline-block;
    font-size: 1rem;
  }

  .DayPicker-wrapper {
    position: relative;
    flex-direction: row;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    // Custom styles
    border: 1px solid theme(default-bdc);
    width: rem(300); // 300px
    background-color: theme(default-bgc);

    @include mq($until: S) {
      font-size: rem(16); // 16px
      line-height: lh(24, 16); // 24px
    }
  }

  .DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .DayPicker-Month {
    display: table;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .DayPicker-NavBar {
    position: absolute;
    top: 0.5em;
    width: 100%;
  }

  .DayPicker-NavButton {
    @include unstyled-button($extend: true, $important: false);
    width: rem(24); // 24px
    height: rem(24); // 24px
    background: url('../../../svg/icon/arrow-white.svg')
      50% /
      auto
      #{rem(11)}
      no-repeat;
    cursor: pointer;
  }

  .DayPicker-NavButton--prev {
    float: left;
    margin-left: rem(8); // 8px
    transform: rotate(180deg);
  }

  .DayPicker-NavButton--next {
    float: right;
    margin-right: rem(8); // 8px
  }

  .DayPicker-NavButton--interactionDisabled {
    display: none;
  }

  .DayPicker-Caption {
    display: table-caption;

    margin: -1px -1px 0;
    padding: rem(8) 0; // 8px
    color: theme(datepickr__months-txt);
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    background-color: theme(datepickr__months-bgc);
  }

  .DayPicker-Caption > div {
    font-weight: 500;
    font-size: 1.15em;
  }

  .DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 1em;
  }

  .DayPicker-WeekdaysRow {
    display: table-row;
  }

  .DayPicker-Weekday {
    display: table-cell;
    padding: 0.5em;
    color: #8b9898;
    text-align: center;
    font-size: 0.875em;
    width: calc(100% / 7);
  }

  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

  .DayPicker-Body {
    display: table-row-group;
  }

  .DayPicker-Week {
    display: table-row;
  }

  .DayPicker-Day {
    display: table-cell;
    padding: rem(5); // 5px
    text-align: center;
    cursor: pointer;
  }

  .DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5em;
    min-width: 1em;
    border-right: 1px solid #eaecec;
    color: #8b9898;
    vertical-align: middle;
    text-align: right;
    font-size: 0.75em;
    cursor: pointer;
  }

  .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
  }

  .DayPicker-Footer {
    padding-top: 0.5em;
  }

  .DayPicker-TodayButton {
    border: none;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    color: #4a90e2;
    font-size: 0.875em;
    cursor: pointer;
  }

  /* Default modifiers */

  .DayPicker-Day--today {
    border-radius: 50%;
    font-weight: 700;
    background-color: theme(datepickr__today-bgc);
  }

  .DayPicker-Day--selected {
    border-radius: 50%;
    font-weight: 700;
    color: theme(datepickr__selected-txt);
    background-color: theme(datepickr__selected-bgc);
  }

  /* DayPickerInput */

  .DayPickerInput {
    position: relative;
  }

  .DayPickerInput-OverlayWrapper {
    position: relative;
  }

  .DayPickerInput-Overlay {
    position: absolute;
    left: 0;
    z-index: 1;

    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  }
}
