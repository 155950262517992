@if $toc {
  /* - Reassurance_________________Reassurance bloc */
} @else {
  /* Reassurance bloc [component]
    ========================================================================== */

  // List
  [class].c-reassurance {
    margin-bottom: rem(16); // 16px
    padding: rem(24) rem(16); // 24px 16px
    background-color: theme(reassurance-bgc);

    @include mq($from: S) {
      margin-bottom: rem(32); // 32px
      padding-top: rem(40); // 40px
      padding-bottom: rem(40); // 40px
    }

    @include mq($from: L) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  // Items
  .c-reassurance__item {
    position: relative;
    padding-left: rem(64); // 64px

    @include mq($from: S) {
      padding-left: 0;
      text-align: center;
    }
  }

  .c-reassurance__item:not(:last-child) {
    margin-bottom: rem(24); // 24px

    @include mq($from: S) {
      margin-bottom: 0;
    }
  }

  .c-reassurance__item::before {
    content: '';
    background-color: theme(default-bgc);
    border-radius: 50%;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    @include mq($until: S) {
      position: absolute;
      top: 0;
      left: 0;
      width: rem(48); // 48px
      height: rem(48); // 48px
      background-size: auto rem(32); // 32px
    }

    @include mq($from: S) {
      display: block;
      margin: 0 auto rem(16); // 16px
      width: rem(88); // 88px
      height: rem(88); // 88px
      background-size: auto rem(42); // 42px
    }
  }

  $list: (
    'production': 'flag',
    'postage': 'gift',
    'shipping': 'clock',
    'ssl': 'secure',
  );
  @each $modifier, $picture in $list {
    .c-reassurance__item--#{$modifier}::before {
      background-image: url('../../../../svg/icon/#{$picture}.svg');
    }
  }

  // Guarante
  .c-reassurance__guarantee {
    @include font($type: bold, $family: $serif);
    @extend %c-h4;
    display: block;
    margin-bottom: rem(4); // 4px

    @include mq($from: S) {
      margin-bottom: rem(2); // 2px
    }
  }

  // Detail
  .c-reassurance__detail {
    display: inline-block;

    @include mq($from: S) {
      font-size: rem(14); // 16px
      line-height: lh(24, 14); // 48px
    }
  }
}
