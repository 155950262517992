@if $toc {
  /* - Safari_____________________Bugfixs for Safari */
} @else {
  /* Bugfixs for Safari [utility]
    ========================================================================== */

  /**
   * - The `-webkit-overflow-scrolling` CSS property controls whether or not touch devices use momentum-based scrolling for a given element. It is only supported in Safari.
   * @see
   * [-webkit-overflow-scrolling](https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-overflow-scrolling)
   */

  @supports (-webkit-overflow-scrolling: auto) {
    // Targets touch type terminals and non-touch terminals that have a width of less than 50em
    @media (hover: none), (hover: hover) and (max-width: 49.99em) {
      .has-unfolded-header .c-header,
      [class].c-nav__list-first-level,
      [class].c-nav__list-second-level {
        /* autoprefixer: ignore next */
        max-height: stretch; /* stylelint-disable-line */ // Correct buggy calculation of height on ios
      }
    }
  }
}
