@if $toc {
  /* - Option_________________Option bloc */
} @else {
  /* Option bloc [component]
    ========================================================================== */

  @include mq($from: S) {
    .c-option__label {
      height: 9px;

      small {
        position: absolute;
        white-space: nowrap;
      }
    }
  }
}
