@if $toc {
  /* - Inline___________________Inline tags <abbr>, <del>, <ins>, <mark>, <small> and <strong> */
} @else {
  /* Default styles for abbr, del, ins, mark, small and strong [element]
   ========================================================================== */

  abbr[title],
  ins {
    text-decoration: none;
  }

  del,
  ins {
    display: inline-block;
    margin: 0 rem(2); // 2px
  }

  del {
    color: theme(del-txt);
    text-decoration: line-through;
  }

  ins {
    color: theme(ins-txt);
  }

  mark {
    @include font($type: semibold, $family: $sans-serif);
    display: inline-block;
    padding: 0 em(7, 14); // 7px
    color: theme(mark-txt);
    font-size: rem(14); // 14px
    line-height: lh(24, 14); // 24px
    background-color: theme(mark-bgc);
  }

  small {
    display: inline-block; // Mandatory for line-height application
    color: theme(default-txt);
    font-weight: normal;

    @include mq($until: S) {
      font-size: rem(14); // 14px
      line-height: lh(18, 14); // 18px
    }

    @include mq($from: S) {
      font-size: rem(12); // 12px
      line-height: lh(18, 12); // 18px
    }
  }

  small a {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  strong {
    font-weight: 600;
  }
}
