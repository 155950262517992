/* Layout wrap [mixin]
  ========================================================================== */

/**
 * Displays 2 or 3 items per line and adjusts margins accordingly
 * @include layout-wrap($item, $gutter);
 *
 * | Parameter   | Type    | Value | Description             |
 * |-------------|---------|-------|-------------------------|
 * |`$item`      | number  | none  | Number of items perline |
 * |`$gutter`    | number  | 24    | Gutter width            |
 *
 *  .usage {
 *    @include layout-wrap($item: 3, $gutter: 0);
 *  }
 */

@mixin layout-wrap($number, $gutter: $grid-gutter) {
  @include equal-width(
    $items: $number,
    $gutter-width: $gutter
  );

  // Last items of the line
  &:nth-child(#{$number}n) {
    margin-right: 0;
  }

  // All items except those in the last line
  &:not(:nth-child(-n+#{$number})) {
    @if $gutter == $grid-gutter {
      margin-top: rem(48); // 48px
    } @else {
      margin-top: rem(32); // 32px
    }
  }
}
