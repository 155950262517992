@if $toc {
  /* - Message list_____________Message list */
} @else {
  /* Message list [component]
    ========================================================================== */

  // Root element
  [class].c-message-list {
    @include mq($until: S) {
      margin-bottom: rem(80); // 80px
    }

    @include mq($from: S) {
      margin-bottom: rem(112); // 112px
      border-bottom: 1px solid theme(default-bdc);
    }
  }


  // List item
  .c-message-list__item {
    position: relative; // Usefull to .c-message-list__btn
    padding: rem(16) 0; // 16px
    border-top: 1px solid theme(default-bdc);
  }


  // Message date
  .c-message-list__date {
    display: block;
    margin-bottom: rem(8); // 8px

    @include mq($from: S) {
      font-size: rem(14); // 14px
      line-height: lh(24, 14); // 24px
    }
  }


  // Message subject
  .c-message-list__subject {
    display: block;

    @include mq($until: S) {
      margin: rem(8) 0 rem(16); // 8px 0 16px 0
    }

    @include mq($from: S) {
      margin-top: rem(8); // 8px
    }
  }


  // See detail button
  .c-message-list__btn {
    @include mq($from: S) {
      position: absolute;
      top: rem(36); // 36px
      right: rem(32); // 32px
    }
  }
}

