@if $toc {
  /* - Hamburgers_______________Hamburger icon animation */
} @else {
  /* Hamburger icon animation [component]
    ========================================================================== */

  /* @author Jonathan Suh @jonsuh
     @see [Hamburgers](https://jonsuh.com/hamburgers/)
  */

  // Settings
  // ==================================================
  $hamburger-padding-x: 0 !default;
  $hamburger-padding-y: 0 !default;
  $hamburger-layer-width: 1.5rem !default;
  $hamburger-layer-height: 0.0625rem !default;
  $hamburger-layer-spacing: 0.3125rem !default;
  $hamburger-layer-color: theme(default-txt) !default;
  $hamburger-layer-border-radius: 0 !default;
  $hamburger-hover-opacity: 1 !default;
  $hamburger-hover-color: theme(brand) !default;
  $hamburger-active-layer-color: $hamburger-layer-color !default;
  $hamburger-active-hover-color: $hamburger-hover-color !default;
  $hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

  // To use CSS filters as the hover effect instead of opacity,
  // set $hamburger-hover-use-filter as true and
  // change the value of $hamburger-hover-filter accordingly.
  $hamburger-hover-use-filter: false !default;
  $hamburger-hover-filter: opacity(50%) !default;
  $hamburger-active-hover-filter: $hamburger-hover-filter !default;

  // Hamburger styles
  // ==================================================

  .c-hamburger {
    display: inline-block;
    margin: 0;
    padding: $hamburger-padding-y $hamburger-padding-x;
    overflow: visible;
    color: inherit;
    font: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: 0.15s;
    transition-property: opacity, filter;

    &:hover,
    &:focus {
      @if $hamburger-hover-use-filter == true {
        filter: $hamburger-hover-filter;
      } @else {
        opacity: $hamburger-hover-opacity;
      }
      color: $hamburger-hover-color;

      .c-hamburger-inner,
      .c-hamburger-inner::before,
      .c-hamburger-inner::after {
        background-color: $hamburger-hover-color;
      }
    }

    &.is-active {
      &:hover,
      &:focus {
        @if $hamburger-hover-use-filter == true {
          filter: $hamburger-active-hover-filter;
        } @else {
          opacity: $hamburger-active-hover-opacity;
        }

        .c-hamburger-inner,
        .c-hamburger-inner::before,
        .c-hamburger-inner::after {
          background-color: $hamburger-active-hover-color;
        }
      }

      .c-hamburger-inner,
      .c-hamburger-inner::before,
      .c-hamburger-inner::after {
        background-color: $hamburger-active-layer-color;
      }
    }
  }

  .c-hamburger-box {
    position: relative;
    display: inline-block;
    width: $hamburger-layer-width;
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  }

  .c-hamburger-inner {
    top: 50%;
    display: block;
    margin-top: calc($hamburger-layer-height / -2);

    &,
    &::before,
    &::after {
      position: absolute;
      width: $hamburger-layer-width;
      height: $hamburger-layer-height;
      background-color: $hamburger-layer-color;
      border-radius: $hamburger-layer-border-radius;
      transition-timing-function: ease;
      transition-duration: 0.15s;
      transition-property: none;
    }

    &::before,
    &::after {
      content: '';
      display: block;
    }

    &::before {
      top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }

    &::after {
      bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }

    [aria-expanded='true'] & {
      transform: rotate(45deg);

      &::before {
        top: 0;
        opacity: 0;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
      }
    }
  }
}
