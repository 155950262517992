@if $toc {
  /* - Wrapper__________________Wrapper and page constraint */
} @else {
  /* Wrapper and page constraint [object]
    ========================================================================== */

  /**
  * Page-level constraining and wrapping elements.
  */

  [class].o-wrapper {
    margin-right: rem(16); // 16px
    margin-left: rem(16); // 16px

    @include mq($from: S) {
      margin-right: rem(24); // 24px
      margin-left: rem(24); // 24px
    }

    @include mq($from: L) {
      max-width: rem(1440); // 1440px
      margin-right: auto;
      margin-left: auto;
    }
  }

  [class].o-wrapper--no-space {
    margin-right: 0;
    margin-left: 0;

    @include mq($from: S) {
      padding-right: rem(24); // 24px
      padding-left: rem(24); // 24px
    }

    @include mq($from: L) {
      margin-right: auto;
      margin-left: auto;
      padding-right: 0;
      padding-left: 0;
    }
  }


  // Responsive class
  [class].o-wrapper--no-space\@um {
    @include mq($until: M) {
      margin-right: 0;
      margin-left: 0;
    }
  }


  // Wrapper & layout
  .o-wrapper.o-layout {
    @include mq($from: S, $until: L) {
      width: auto;
    }
  }
}
