@if $toc {
  /* - Video____________________Video player */
} @else {
  /* Video player [component]
    ========================================================================== */

  .c-video {
    position: relative;  // Usefull to ::before
    left: 50%;
    max-width: rem(953); // 953px
    max-height: rem(536); // 536px
    transform: translateX(-50%);

    &::before {
      content: "";
      display: block;
      padding-top: 56.25%; // 16/9 format
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
}
