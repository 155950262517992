@if $toc {
  /* - Favorite_________________Add / remove favorites */
} @else {
  /* Add / remove favorites [component]
    ========================================================================== */

  /**
  * @see  unstyled-button` mixin is defined in `./source/assets/styles/scss/tools/_tools.unstyled-button.scss`
  */

  .c-favorite {
    @include unstyled-button($extend: true, $important: false);
    position: absolute;
    top: rem(8); // 8px
    right: rem(8); // 8px
    width: rem(32); // 32px
    height: rem(32); //32px

    .c-slider--product & {
      top: rem(12); // 12px
      right: rem(12); // 12px
      z-index: 1; // Place the text above the slider
    }
  }


  // Context-specific positioning
  // Display 2 items side by side on mobile
  .c-favorite {
    @include mq($until: S) {
      .o-layout-wrap &,
      .c-featured-product & {
        top: rem(4); // 4px
        right: rem(4); // 4px
      }
    }
  }
}
