@if $toc {
  /* - Tag______________________Product tags */
} @else {
  /* Product tag [component]
    ========================================================================== */

  // Accessory
  .c-tag--accessory {
    display: inline-block;
    padding: rem(8) rem(16) rem(8) rem(48); // 8px 16px 8px 48px
    color: inherit;
    font-weight: inherit;
    background: theme(tag--highlight-bgc)
      url('../../../svg/icon/gift.svg')
      #{rem(12)}
      #{rem(4)} /
      #{rem(32)}
      #{rem(32)}
      no-repeat;

    @include mq($from: S) {
      font-size: rem(14); // 14px
      line-height: lh(24, 14); // 24px
      background-size: #{rem(28)} #{rem(28)}; // 28px 28px
    }
  }

  [class].c-tag--container.u-mb-4 {
    display: flex;

    @include mq($until: S) {
      display: block;
      margin-bottom: 1rem;
    }
  }

  // Highlight
  // Mark
  // Big mark
  // Big highlight
  .c-tag--mark,
  .c-tag--big-mark,
  .c-tag--highlight,
  .c-tag--big-highlight {
    @include font($type: semibold, $family: $sans-serif);
    display: inline-block;
    font-size: rem(14); // 14px
    font-weight: 400;
    line-height: lh(24, 14); // 24px
  }

  // Highlight
  // Mark
  .c-tag--mark,
  .c-tag--highlight {
    padding-right: em(7, 14); // 7px
    padding-left: em(7, 14); // 7px
  }

  // Big mark
  // Big highlight
  .c-tag--big-mark,
  .c-tag--big-highlight {
    padding: rem(8) rem(12); // 8px 12px
  }

  // Mark
  // Big mark
  .c-tag--mark,
  .c-tag--big-mark {
    color: theme(mark-txt);
    background-color: theme(mark-bgc);
  }

  // Highlight
  .c-tag--highlight {
    color: theme(tag--highlight-txt);
    background-color: theme(tag--highlight-bgc);
  }

  // Big highlight
  .c-tag--big-highlight {
    text-transform: uppercase;
    vertical-align: bottom;
    background-color: theme(tag--highlight-bgc);
  }

  // Remove & Remove all
  .c-tag--remove,
  .c-tag--remove-all {
    color: theme(default-txt);
    font-weight: 400;
    font-size: rem(14); // 14px
    line-height: lh(18, 14); // 18px
    border: 1px solid theme(default-bdc);

    #{$interactions} {
      text-decoration: none;
      border-color: theme(tag--remove-focus-bdc);
    }

    @include mq($from: S) {
      font-size: rem(12); // 12px
      line-height: lh(18, 12); // 18px
    }
  }

  // Remove
  .c-tag--remove {
    padding: rem(2) rem(32) rem(2) rem(8); // 2px 32px 2px 8px
    background: url('../../../svg/icon/cross.svg')
      top
      50%
      right
      #{rem(8)} /
      auto
      #{rem(11)}
      no-repeat;

    &:not(:last-child) {
      margin-bottom: rem(8); // 8px
    }
  }

  // Remove all
  .c-tag--remove-all {
    @include font($type: semibold);
    padding: rem(5) rem(8); // 5px 8px
    text-transform: uppercase;

    @include mq($from: M) {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
