@if $toc {
  /* - Feature_________________Feature bloc */
} @else {
  /* Feature bloc [component]
    ========================================================================== */

  // List
  [class].c-feature {
    position: relative; // Usefull to ::before
    padding: rem(24) rem(16); // 24px 16px

    @include mq($from: S) {
      // border-top: 1px solid theme(default-bdc);
      padding: rem(48) rem(24) rem(72); // 48px 24px 72px 24px
    }

    @include mq($from: L) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  // Items
  .c-feature__item {
    background-size: auto rem(40); // 40px
    background-repeat: no-repeat;

    @include mq($until: S) {
      margin-bottom: rem(24); // 24px
      padding: rem(8) 0 rem(8) rem(56); // 8px 0 8px 56px
      background-position: 0 50%;
    }

    @include mq($from: S) {
      position: relative; // Usefull to ::after
      padding-top: rem(56); // 56px
      text-align: center;
      background-position: 50% 0;
    }
  }

  $list: (
    'diamond',
    'ethical-gold',
    'gold-18ct',
    'gold-9ct',
    'silver',
    'stainless-steel',
    'silvery-metal',
    'metal',
    'wood',
    'guarantee',
    'certificate',
    'production',
    'engraving',
    'guide',
    'vermeil'
  );

  @each $modifier in $list {
    .c-feature__item--#{$modifier} {
      background-image: url('../../../../svg/icon/#{$modifier}.svg');
    }
  }

  // Decoration stars
  @include mq($from: S) {
    .c-feature__item:first-child::before,
    .c-feature__item::after {
      content: '';
      position: absolute;
      top: rem(24); // 24px
      width: rem(24); // 24px
      height: rem(24); // 24px
      background: url('../../../../svg/icon/stars.svg') 50% 50% / #{rem(28)} #{rem(
          28
        )} no-repeat;
    }

    .c-feature__item:first-child::before {
      left: rem(-24); // 24px
    }

    .c-feature__item::after {
      right: rem(-24); // 24px
    }
  }

  // Content
  .c-feature__content {
    @include font($type: bold, $family: $serif);
    @extend %c-h4;
    color: theme(feature-txt);
  }

  // Specific case: when there are 6 items
  // - horizontal padding is removed
  // - the right star is hidden on the last item
  // - the left star is hidden on the first item
  @include mq($from: S) {
    .c-feature--max {
      padding-right: 0;
      padding-left: 0;

      .c-feature__item:first-child::before,
      .c-feature__item:last-child::after {
        content: normal;
      }
    }
  }
}
