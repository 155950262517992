/* Fonts classes must mirror ProductOptionValue::ENGRAVING_FONTS
  ========================================================================== */
input.laser-1,
input.laser-2,
input.laser-3,
input.laser-4,
input.laser-5,
input.laser-6,
input.laser-7,
input.diamond-1,
input.diamond-2,
input.diamond-3,
input.diamond-4,
input.diamond-5 {
  font-weight: normal;
  line-height: 1.5;
}

input.laser-1,
input.diamond-1 {
  font-family: Arial, sans-serif;
  font-size: 1.5rem;
  padding: 6px 10px;
}

input.laser-2 {
  font-family: 'Police Laser 2';
  font-size: 1.7rem;
  // Cursive font was visualy cut on left side (with text like Joséphine)
  padding: 0.34rem 1rem 0.34rem 0;
  text-indent: 1rem;
}

input.laser-3 {
  font-family: Times, 'Times New Roman', serif;
  font-size: 1.5rem;
}

input.laser-4 {
  font-family: 'Police Laser 4';
  font-size: 1.7rem;
  padding: 6px 10px 6px 3px;
  text-indent: 10px;
}

input.laser-5 {
  font-family: 'Police Laser 5';
  font-size: 1.7rem;
  padding: 8px 10px 8px 3px;
  text-indent: 10px;
}

input.laser-6 {
  font-family: 'Police Laser 6';
  font-size: 1.7rem;
  // Cursive font was visualy cut on left side (with text like Joséphine)
  padding: 8px 10px 8px 3px;
  text-indent: 10px;
}

input.laser-7 {
  font-family: 'Police Laser 7';
  font-size: 1.8rem;
  // Cursive font was visualy cut on left side (with text like Joséphine)
  padding: 8px 10px 8px 3px;
  text-indent: 10px;
}

input.diamond-2 {
  font-family: 'Police Diamant 2';
  font-size: 2rem;
  // Cursive font was visualy cut on left side (with text like Joséphine)
  padding: 2px 10px 2px 3px;
  text-indent: 7px;
}

input.diamond-3 {
  font-family: 'Police Diamant 3';
  font-size: 1.5rem;
  // Cursive font was visualy cut on left side (with text like Joséphine)
  padding: 6px 10px;
}

input.diamond-4 {
  font-family: 'Police Diamant 4';
  font-size: 1.5rem;
  // Cursive font was visualy cut on left side (with text like Joséphine)
  padding: 8px 10px;
}

input.diamond-5 {
  font-family: 'Police Diamant 5';
  font-size: 1.7rem;
  // Cursive font was visualy cut on left side (with text like Joséphine)
  padding: 4px 10px;
}
