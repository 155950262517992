// Wrapper
input[type='file'] {
  border: 1px solid black;
}

input[type='file']::file-selector-button {
  @include unstyled-button($extend: true, $important: false);

  display: inline-block;
  padding: rem(11) rem(32); // 11px 32px
  font-weight: 600;
  letter-spacing: inherit;
  border-width: 1px;
  border-style: solid;
  transition: all var(--transition-duration);

  color: theme(btn--primary-txt);
  background-color: theme(btn--primary-bgc);
  border-color: theme(btn--primary-bdc);

  #{$interactions} {
    color: theme(btn--primary-txt_hover);
    background-color: theme(btn--primary-bgc_hover);
    border-color: theme(btn--primary-bdc_hover);
  }
}
