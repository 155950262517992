@if $toc {
  /* - Form_____________________Form elements */
} @else {
  /* Form [element]
    ========================================================================== */

  // Search form
  form[role='search'] {
    $max-width: $grid-col * 6 + $grid-gutter * 5;
    max-width: rem($max-width);
    margin-right: auto;
    margin-left: auto;
  }

  // Fieldset
  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  // Label
  label {
    display: inline-block;
    margin-bottom: rem(4); // 4px
    text-align: left;

    @include mq($from: S) {
      font-size: rem(14); // 14px
      line-height: lh(24, 14); // 24px
    }

    &[for] {
      /* stylelint-disable-line */
      cursor: pointer;
    }

    // Non mandatory fields
    span {
      color: theme(label__span-txt);

      &::before {
        content: '−';
        display: inline-block;
        margin-right: rem(4); // 4px
      }
    }
  }

  // Input, select and textarea
  input:not([type='range']),
  select,
  textarea {
    display: block;
    width: 100%;
    padding: rem(11) 1rem; // 11px 16px
    color: theme(default-txt);
    font-weight: 600;
    letter-spacing: inherit;
    background-color: theme(default-bgc);
    border: 1px solid theme(default-bdc);
    border-radius: 0;
    appearance: none; // Remove shadow on iOS

    &:focus {
      border-color: theme(input-focus-bdc);
      outline: 0;
    }

    @include mq($from: S) {
      font-size: rem(14); // 14px
      line-height: lh(24, 14); // 24px
    }

    &::placeholder {
      /* stylelint-disable-line */
      color: theme(form-plh);
      opacity: 1;
    }

    &:invalid {
      box-shadow: none;
    }

    // Error field
    &[aria-invalid='true'] {
      border-color: theme(form-error-bdc);
    }
  }

  input {
    // Input type number
    &[inputmode='numeric'] {
      @include mq($until: S) {
        width: 50%;
      }
    }

    // Input type search
    &[type='search'] {
      outline-offset: 0; // Remove shadow on iOS
      appearance: none; // Remove rounded corner on iOS
    }
  }

  textarea {
    min-height: rem(264); // 264px = 10 lines + padding & border
    font-weight: normal;
    resize: vertical;

    @include mq($from: S) {
      min-height: rem(144); // 144px = 5 lines + padding & border
    }
  }

  // Checkboxes and radio buttons
  // Labels are defined in `_components.label.scss`
  input[type='checkbox'],
  input[type='radio'] {
    @include visually-hidden($extend: true, $important: false);

    &:focus + label {
      outline: 1px dotted theme(default-outline);
    }
  }

  // Legend
  legend {
    width: 100%;

    button {
      @include unstyled-button($extend: true, $important: false);
      width: inherit;
      letter-spacing: inherit;
    }
  }
}
