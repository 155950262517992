@if $toc {
  /* - Box-sizing_______________Better default `box-sizing` */
} @else {
  /* Box model
    ========================================================================== */

  /**
  * Set the global `box-sizing` state to `border-box` so that `width` is not affected by `padding` or `border`.
  * @see [Box-sizing](https://hankchizljaw.com/wrote/a-modern-css-reset/#heading-breaking-it-down)
  */

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}
