@if $toc {
  /* - Nav______________________Main site navigation */
} @else {
  /* Main site navigation [component]
    ========================================================================== */

  // c-nav__list-first-level's width on desktop
  $width: 2 * $grid-gutter + 3 * $grid-col;

  // Common rules
  // Nav list (root level)
  .c-nav__list-root-level {
    text-transform: uppercase;
  }

  // Nav list (first level)
  .c-nav__list-first-level {
    text-transform: none;
  }

  // Nav list item (second level)
  .c-nav__sub-sub-item {
    margin: rem(6) 0 rem(6) rem(16); // 6px 0 6px 16px
    background-color: theme(default-bgc);
  }

  // Nav back items  (first level)
  .c-nav__back-item {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: rem(6) 0; // 6px 0
    font-size: rem(18); // 18px
    line-height: lh(36, 18); // 36px
    border-bottom: 1px solid theme(default-bdc);
  }

  // Nav link (second level)
  .c-nav__sub-item .c-nav__link {
    font-weight: 600;
  }

  // Nav link with subsubmenu
  .c-nav__sub-item--with-submenu > .c-nav__link {
    font-weight: 700;
  }

  /*/ All items (second level)
  .c-nav__sub-sub-item--all {
    margin-top: rem(16); // 16px
    margin-bottom: 0;
    padding-bottom: rem(16); // 16px
  }*/

  // Nav link
  .c-nav__link {
    // font-size: rem(14); // 14px
    line-height: lh(28, 14); // 28px
    color: theme(default-txt);

    #{$interactions},
    &[aria-current] {
      color: theme(brand);
      text-decoration: none;
    }
  }

  // All links (first & second level)
  [class].c-nav__link--all {
    color: theme(link-txt);
    font-weight: 600;
    background: 0;

    #{$interactions} {
      text-decoration: underline;
    }
  }

  // All links (first level)
  [class].c-nav__link--guide {
    padding-left: rem(12); // 12px
    color: theme(link-txt);
    font-weight: 700;
    background: url('../../../../svg/icon/arrow.svg')
      0
      50% /
      #{rem(11)}
      #{rem(11)}
      no-repeat;

    #{$interactions} {
      text-decoration: underline;
    }
  }

  // Nav link (second level): remove arrow
  .c-nav__sub-sub-item .c-nav__link {
    font-weight: normal;
    background: 0;
  }

  // Back buttons (first & second level)
  .c-nav__back-button {
    @include unstyled-button($extend: true, $important: false);
    // color: theme(btn--light-txt);
    width: 100%;
    // font-size: rem(14); //14px
    line-height: lh(21, 14); // 21px
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    background: url('../../../../svg/icon/arrow-back.svg')
      #{rem(16)}
      50% /
      #{rem(11)}
      #{rem(11)}
      no-repeat;

    #{$interactions} {
      color: theme(brand);
    }
  }

  // Nav tag
  // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
  @media (hover: none),
    (hover: hover) and (max-width: 49.99em),
    (-ms-high-contrast: none) and (max-width: 49.99em) {
    .c-nav {
      text-align: center;
      background-color: theme(default-bgc);
      border-bottom: 1px solid theme(brand);
      transition: transform var(--transition-duration);

      .o-wrapper {
        margin: 0;
      }

      .has-unfolded-header & {
        display: block;
        order: -1;
        border-bottom: 0;
      }

      &.first-level-is-displayed {
        transform: translateX(-100%);
      }

      &.second-level-is-displayed {
        transform: translateX(-200%);
      }
    }
  }

  // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
  @media (hover: hover) and (min-width: 50em),
    (-ms-high-contrast: none) and (min-width: 50em) {
    .c-nav {
      // position: relative; // Usefull to .c-nav__list
      display: block; // Override hidden attribute
      font-size: rem(14); // 14px
      line-height: lh(21, 14); // 21px
      border-bottom: 1px solid theme(default-bdc);
    }
  }

  // Nav list (root level)
  [class].c-nav__list-root-level {
    text-align: left;

    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      display: none;
      padding-top: rem(8); // 8px
      padding-bottom: rem(16); // 16px
      border-bottom: 1px solid theme(default-bdc);
      margin-bottom: rem(16); // 16px

      .has-unfolded-header & {
        display: block;
      }
    }

    // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
    @media (hover: hover) and (min-width: 50em),
      (-ms-high-contrast: none) and (min-width: 50em) {
      display: flex;
    }
  }

  // Nav list item
  // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
  @media (hover: none),
    (hover: hover) and (max-width: 49.99em),
    (-ms-high-contrast: none) and (max-width: 49.99em) {
    .c-nav__item {
      padding-top: 0.5rem; // 12px
    }
  }

  // Targets mobiles in landscape mode, non-touch terminals that have a width of less than 50em in landscape mode and IE11 under 50em in landscape mode
  @media (hover: none) and (max-width: 49.99em) and (orientation: landscape),
    (hover: hover) and (max-width: 49.99em) and (orientation: landscape),
    (-ms-high-contrast: none) and (max-width: 49.99em) and (orientation: landscape) {
    .c-nav__item {
      padding-top: rem(12); // 12px
    }
  }

  // Nav link
  .c-nav__link {
    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      display: block;
      padding-right: rem(16); // 16px
      padding-left: rem(16); // 16px
      background: url('../../../../svg/icon/arrow.svg') right #{rem(16)} top 50% /
        #{rem(11)} #{rem(11)} no-repeat;

      // Nav link (first level)
      .c-nav__item > & {
        background: url('../../../../svg/icon/arrow.svg') right #{rem(16)} top 50% /
          #{rem(11)} #{rem(11)} no-repeat;
      }

      // Nav link with subsubmenu
      .c-nav__sub-item--with-submenu > & {
        text-transform: uppercase;
      }
    }

    // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
    @media (hover: hover) and (min-width: 50em),
      (-ms-high-contrast: none) and (min-width: 50em) {
      font-size: rem(14); // 14px
      line-height: lh(21, 12); // 21px

      // Nav link (first level)
      .c-nav__item > & {
        // padding: rem(16) 0 rem(17); // 16px 0 17px 0
        border-bottom: 2px solid transparent;
      }
    }
  }

  // Nav link without submenu
  .c-nav__item--without-submenu .c-nav__link {
    background-image: none;
  }

  // Nav list item (root level)
  // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
  @media (hover: none),
    (hover: hover) and (max-width: 49.99em),
    (-ms-high-contrast: none) and (max-width: 49.99em) {
    .c-nav__item {
      .first-level-is-displayed &,
      .second-level-is-displayed & {
        display: none;
      }

      &.is-active {
        display: list-item;
      }
    }
  }

  // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
  @media (hover: hover) and (min-width: 50em),
    (-ms-high-contrast: none) and (min-width: 50em) {
    .c-nav__item {
      // position: relative; // Usefull to c-nav__list-first-level
      padding: rem(12) 0; // 12px 0

      // IE11 & Edge need separate instruction
      &:hover,
      &:focus {
        .c-nav__list-first-level {
          display: flex;
        }

        > .c-nav__link {
          color: inherit;
          border-bottom-color: theme(brand);
        }
      }

      &:focus-within {
        .c-nav__list-first-level {
          display: flex;
        }

        > .c-nav__link {
          color: inherit;
          border-bottom-color: theme(brand);
        }
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .c-nav__item {
    // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
    @media (hover: hover) and (min-width: 50em),
      (-ms-high-contrast: none) and (min-width: 50em) {
      padding-right: rem(12); // 12px
      padding-left: rem(12); // 12px
    }

    @include mq($from: M) {
      padding-right: rem(20); // 20px
      padding-left: rem(20); // 20px
    }
  }

  // Nav list (first & second level)
  [class].c-nav__list-first-level,
  [class].c-nav__list-second-level {
    // [class].c-nav__list-first-level {
    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      width: 100%;
      padding-top: rem(62); // 62px
      background-color: theme(default-bgc);
    }
  }

  .is-active > .c-nav__list-first-level,
  .is-active > .c-nav__list-second-level {
    // .is-active > .c-nav__list-first-level {
    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      display: block;
    }
  }

  // Nav list (first level)
  [class].c-nav__list-first-level {
    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      height: 100vh;
    }

    // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
    @media (hover: hover) and (min-width: 50em),
      (-ms-high-contrast: none) and (min-width: 50em) {
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      display: none;
      padding: rem(16); // 8px 16px
      background-color: theme(default-bgc);
      border-top: 1px solid theme(default-bdc);
      border-bottom: 1px solid theme(brand);
    }
  }

  // Nav list (first level) last item
  .c-nav__item:last-child .c-nav__list-first-level {
    // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
    @media (hover: hover) and (min-width: 50em),
      (-ms-high-contrast: none) and (min-width: 50em) {
      left: auto;
      transform: none;
      right: rem(-20); // -20px
    }
  }

  // Nav list item (second level)
  [class].c-nav__list-second-level {
    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      height: calc(100vh - #{rem(60)}); // 100vh - 60px (=  c-shortcut's height)
      overflow-y: auto;
    }

    // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
    @media (hover: hover) and (min-width: 50em),
      (-ms-high-contrast: none) and (min-width: 50em) {
      margin-top: rem(16); // 16px
      margin-right: rem(16); // 16px
    }
  }

  // Nav list item (first level)
  .c-nav__sub-item {
    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      margin-top: rem(12); // 12px
    }

    // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
    @media (hover: hover) and (min-width: 50em),
      (-ms-high-contrast: none) and (min-width: 50em) {
      width: 25%;
      margin: rem(16) 0; // 16px 0

      &--guide {
        margin-top: rem(12); // 12px
      }
    }
  }

  // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
  @media (hover: none),
    (hover: hover) and (max-width: 49.99em),
    (-ms-high-contrast: none) and (max-width: 49.99em) {
    .c-nav__sub-item {
      .second-level-is-displayed & {
        display: none;
      }

      &.is-active {
        display: list-item;

        // Category "nav back" button is visible when category "is-active" class is set.
        // The problem sub category navigation keeps the first/previous level "nav back"
        // visible even if there is a "nav back" button dedicated to the sub navigation.
        // This is somehow a hack to prevent both button labels to be shown and also to
        // prevent wrong "nav back" button click.
        .c-nav__back-item {
          z-index: 2;
          background-color: white;
        }
      }
    }
  }

  // Nav list item (first level)
  .c-nav__sub-item--all {
    // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
    @media (hover: hover) and (min-width: 50em),
      (-ms-high-contrast: none) and (min-width: 50em) {
      display: none;
    }
  }

  /*/ Nav illustration item (first level)
  .c-nav__sub-item--illustration {
    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      position: absolute;
      right: rem(16); // 16px
      bottom: rem(60); //60px
      left: rem(16); // 16px
    }

    // Targets touch terminals in landscape mode, non-touch terminals that have a width of less than 50em in landscape mode and IE11 under 50em in landscape mode
    @media (hover: none) and (orientation: landscape),
      (hover: hover) and (max-width: 49.99em) and (orientation: landscape),
      (-ms-high-contrast: none) and (max-width: 49.99em) and (orientation: landscape) {
      display: none;
    }

    // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
    @media (hover: hover) and (min-width: 50em),
      (-ms-high-contrast: none) and (min-width: 50em) {
      margin-left: auto;
      text-align: right;
    }
  }*/

  // Nav back items  (first level)
  .c-nav__back-item {
    // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
    @media (hover: hover) and (min-width: 50em),
      (-ms-high-contrast: none) and (min-width: 50em) {
      display: none;
    }
  }

  /*/ Nav illustration links (first level)
  .c-nav__illustration-link {
    &:focus {
      outline: 1px dotted theme(default-outline);
    }

    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      display: block;

      img {
        width: 100%;
        height: auto;
      }
    }
  }*/
}
