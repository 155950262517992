@if $toc {
  /* - hidden___________________Hides elements */
} @else {
  /* Hides elements [utility]
    ========================================================================== */

  [class].u-hidden {
    display: none;
  }

  [class].u-hidden\@us {
    @include mq($until: S) {
      display: none;
    }
  }

  [class].u-hidden\@um {
    @include mq($until: M) {
      display: none;
    }
  }

  [class].u-hidden\@fs {
    @include mq($from: S) {
      display: none !important;
    }
  }

  [class].u-hidden\@fm {
    @include mq($from: M) {
      display: none;
    }
  }

  // Honeypot-Captcha field masking
  [class].hp-confirm-field {
    display: none !important;
  }
}
