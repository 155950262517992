@if $toc {
  /* - Font-size________________Font-size */
} @else {
  /* Font-size [utility]
    ========================================================================== */

  [class].u-font-sm {
    @include mq($from: S) {
      font-size: rem(14); // 14px
      line-height: lh(24, 14); // 24px
    }
  }
}
