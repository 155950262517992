@if $toc {
  /* - Address___________________Address block */
} @else {
  /* Address block [element]
   ========================================================================== */

  address {
    font-style: normal;
    font-weight: normal;
    text-align: left;
  }
}
