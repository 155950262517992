@if $toc {
  /* - visually-hidden__________Hides visually */
} @else {
  /* Hides visually [utility]
    ========================================================================== */

  [class].u-visually-hidden {
    @include visually-hidden($extend: false, $important: false);
  }
}
