.c-glide--active .c-glide__track {
  .c-pagination__item {
    top: 100%;
    z-index: 1;
  }
}

.c-glide--active .c-glide__bullet-wrapper {
  li {
    display: inline;
  }
  .slick-active .c-glide__bullet {
    opacity: 1;
  }
  .c-glide__bullet {
    &:focus,
    &:hover,
    &:active {
      opacity: 1;
    }
  }
}

@media (min-width: 50em) {
  .c-glide--active .c-glide__thumbnail_wrapper--subslider {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;

    .c-pagination__item {
      position: relative;
      margin-top: 0.5rem;
      z-index: 1;

      &.c-pagination__item--disabled {
        filter: unquote('grayscale()');
        pointer-events: none;
      }
    }

    .c-pagination__link {
      height: 100%;
      border: none;
      border-radius: 0;
      width: 3.5rem;
    }

    .c-pagination__item--previous {
      left: 0;
      margin-right: 0.375rem;
    }

    .c-pagination__item--next {
      right: 0;
      margin-left: 0.375rem;
    }

    .slick-list {
      flex-grow: 1;
    }

    .slick-current .c-glide__thumbnail,
    .c-glide__thumbnail.is-active {
      border-color: #ba8d26;
    }
  }
}
