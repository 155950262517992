@if $toc {
  /* - Error____________________404 error */
} @else {
  /* 404 error [component]
  ========================================================================== */

  // Main
  .c-error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* autoprefixer ignore next */
    max-height: stretch; /* stylelint-disable-line */
    background-color: theme(error-bgc);
  }

  // Error number
  .c-error__number {
    @include font($type: bold, $family: $serif);
    display: block;
    margin-bottom: rem(8); // 8px
    color: theme(brand);
    font-size: rem(80); // 80px
    line-height: lh(80, 80); // 80px

    @include mq($from: S) {
      margin-bottom: rem(16); // 16px
    }
  }

  // Error title
  .c-error__title {
    @extend %c-h3;
    margin-bottom: rem(24); // 24px
    color: theme(brand);

    @include mq($from: S) {
      margin-bottom: rem(48); // 48px
    }
  }

  // Error image
  .c-error__illustration {
    width: rem(616); // 616px
    height: rem(228); // 228px
    margin: 0 auto;

    @include mq($from: S) {
      margin-top: rem(48); // 48px
    }
  }
}
