@if $toc {
  /* - Cart total_______________Cart total */
} @else {
  /* Cart total [component]
    ========================================================================== */

  // Wrapper
  .c-cart-total {
    overflow: hidden; // Avoid margin-collapsing of .c-btn
    background-color: theme(default-bgc);

    @include mq($until: S) {
      padding: rem(8) rem(16); // 8px 16px
    }
  }

  // Title
  .c-cart-total__title {
    @extend %c-h4;
    color: theme(brand);

    @include mq($from: S) {
      margin: rem(16); // 16px
    }
  }

  // Table
  .c-cart-total__table {
    font-weight: 600;
    table-layout: fixed;
    border-top: 1px solid theme(default-bdc);

    @include mq($from: S) {
      margin-bottom: rem(8); // 8px
    }
  }

  .c-cart-total__table {
    th,
    td {
      width: 50%;
      padding-top: rem(8); // 8px
      padding-bottom: rem(8); // 8px
      vertical-align: top;

      @include mq($from: S) {
        padding: rem(16); // 16px
      }
    }

    th {
      text-align: left;
    }
  }

  // Amount
  .c-cart-total__amount {
    color: theme(cart-total__amount-txt);
    text-align: right;
  }

  // Delivery method
  // Delivery cost
  [class].c-cart-total__delivery-method,
  [class].c-cart-total__delivery-cost {
    padding-bottom: 0;
    border-bottom: 0;
  }

  // Delivery cost
  .c-cart-total__delivery-cost {
    color: theme(cart-total__amount-txt);
    &.free {
      color: theme(cart-total__delivery-cost-txt);
    }
    text-align: right;
  }

  // Address
  // Date
  .c-cart-total__hidden {
    border-bottom: 0;

    span {
      @include visually-hidden($extend: true, $important: false);
    }

    + td {
      display: block;
      width: 200%;
      text-align: left;
      transform: translateX(-50%);
    }
  }

  // Article detail
  [class].c-cart-total__article-detail {
    padding-top: 0;
    line-height: lh(21, 14); // 21px
    background-color: theme(default-bgc);
    transform: translate(-50%, #{rem(-8)});
  }

  // Article names
  .c-cart-total__article-name {
    width: 75%;
    padding-bottom: rem(4);
    font-weight: normal;
  }

  // Article prices
  .c-cart-total__article-price {
    width: 25%;
    text-align: right;
  }

  // Address
  .c-cart-total__delivery-address {
    line-height: lh(21, 16); // 21px

    @include mq($from: S) {
      line-height: lh(21, 14); // 21px
    }

    /* stylelint-disable */
    th,
    td {
      padding-bottom: 0;
      border-bottom: 0;
    }
    /* stylelint-enable */
  }

  // Address
  .c-cart-total__delivery-date {
    th {
      border-bottom: 0;
    }
  }

  // Footer
  .c-cart-total__footer {
    @include font($type: bold, $family: $serif);
    @extend %c-h3;

    th,
    td {
      border-bottom: 0;

      @include mq($until: S) {
        padding-top: rem(16); // 16px
      }
    }
  }

  // Place an order button
  .c-cart-total__order {
    margin: 0 rem(16) rem(40); // 0 16px 40px 16px
    text-align: center;

    @include mq($until: S) {
      position: fixed;
      right: 0;
      bottom: rem(60); // 60px (= c-shortcut's height)
      left: 0;
      z-index: 666;
      margin: 0;
      padding: rem(8) rem(16); // 8px 16px
      background-color: theme(default-bgc);
    }
  }
}
