@if $toc {
  /* - Progressbar______________Progressbar */
} @else {
  /* Progressbar [component]
    ========================================================================== */

  // List
  [class].c-progressbar {
    position: relative;
    margin-top: rem(16); // 16px
    margin-bottom: rem(16); // 16px

    @include mq($from: S) {
      margin-top: rem(24); // 24px
      margin-bottom: rem(24); // 24px
      padding: rem(12) 0; // 12px 0
      border: 1px solid rgba(theme(progressbar-txt), 0.3);
    }
  }

  // Gauge
  .c-progressbar::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: rem(8); // 8px
    border: 1px solid theme(progressbar-txt);
    opacity: 0.3;

    @include mq($from: S) {
      content: normal;
      top: auto;
      right: 0;
      width: auto;
      height: rem(8); // 8px
    }
  }

  // Items
  .c-progressbar__item {
    position: relative; // Usefull to pseudo-elements
  }

  .c-progressbar__item {
    &::before,
    &::after {
      content: '';
      position: absolute;
    }

    // Gauge
    &::before {
      left: 0;
      background-color: theme(progressbar-txt);
    }

    // Arrow
    &::after {
      width: 0;
      height: 0;
      border: rem(8) solid transparent;
    }

    // Next steps after the current step
    [aria-current='step'] ~ & {
      opacity: 0.3;

      &::before,
      &::after {
        content: normal;
      }
    }
  }

  @include mq($until: S) {
    .c-progressbar__item {
      &:first-child {
        padding-top: rem(4); // 4px
      }

      &:last-child {
        padding-bottom: rem(4); // 4px
      }

      &::before {
        top: 0;
        bottom: 0;
        width: rem(8); // 8px
      }

      &::after {
        top: 50%;
        left: rem(8); // 8px
        margin-top: rem(-8); // -8px
        border-left-color: theme(progressbar-txt);
      }
    }
  }

  @include mq($from: S) {
    .c-progressbar__item {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      &::before {
        right: 0;
        bottom: rem(-20); // 20px
        left: -1px;
        height: rem(8); // 8px
      }

      &::after {
        bottom: rem(-12); // 12px
        left: 50%;
        margin-left: rem(-8); // -8px
        border-bottom-color: theme(progressbar-txt);
      }
    }
  }

  // Content
  .c-progressbar__content {
    /* stylelint-disable-line */
    display: inline-block;
    padding: rem(8) rem(64); // 8px 64px
    background-repeat: no-repeat;
    background-position: top 50% left #{rem(24)};
    background-size: auto rem(30); // 30px

    @include mq($from: S) {
      padding: rem(8) rem(48); // 8px 48px
      background-position: top 50% left 0;
      background-size: auto rem(40); // 40px
    }
  }

  $states: 'validated', 'pending', 'delivery', 'delivered'; /* stylelint-disable-line */
  @each $state in $states {
    .c-progressbar__item--#{$state} .c-progressbar__content {
      background-image: url('../../../../svg/icon/progress-#{$state}.svg');
    }
  }
}
