@if $toc {
  /* - Container________________Centered container */
} @else {
  /* Centered container [object]
    ========================================================================== */

  // Responsive class
  @include mq($from: S) {
    [class*="o-container"] {
      margin-right: auto;
      margin-left: auto;
    }

    $cols: 3, 4, 5, 6;  /* stylelint-disable-line */

    @each $col in $cols {
      .o-container-#{$col} {
        $gutter : $col - 1;
        $width: $col * $grid-col + $gutter * $grid-gutter;
        max-width: rem($width);
      }
    }
  }
}
