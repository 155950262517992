@if $toc {
  /* - Material_________________Material in which the products are made */
} @else {
  /* Material in which the products are made [component]
    ========================================================================== */

  .c-material {
    position: absolute;
    right: rem(8); // 8px
    bottom: rem(8); // 8px
    width: rem(56); // 56px
    height: rem(56); // 56px
    font-weight: 700;
    font-size: rem(13); // 14px
    line-height: lh(15, 13); // 15px
    text-transform: uppercase;
    border: 1px solid currentColor;
    border-radius: 50%;

    @include mq($from: S) {
      width: rem(58); // 58px
      height: rem(58); // 58px
      font-size: rem(12); // 12px
      line-height: lh(15, 12); // 15px
    }
  }

  .c-material--gold {
    color: theme(material--gold-txt);
  }

  .c-material--rose-gold {
    color: theme(material--rose-gold-txt);
  }

  .c-material--white-gold {
    color: theme(material--white-gold-txt);
  }

  .c-material--nacre {
    color: theme(material--nacre-txt);
  }

  .c-material--silver {
    color: theme(material--silver-txt);
  }

  // Content
  .c-material__content {
    display: table-cell;
    width: inherit;
    height: inherit;
    text-align: center;
    vertical-align: middle;
  }

  // Context-specific positioning
  .c-material {
    // Display of 2 items side by side on mobile
    @include mq($until: S) {
      .o-layout-wrap &,
      .c-featured-product & {
        right: rem(4); // 4px
        bottom: rem(4); // 4px
        width: rem(52); // 52px
        height: rem(52); // 52px
        font-size: rem(12); // 12px
        line-height: lh(15, 12); // 15px
      }
    }

    // Opposite slider display
    @include mq($from: S) {
      .c-slider--opposite & {
        width: rem(52); // 52px
        height: rem(52); // 52px
      }
    }
  }
}
