@if $toc {
  /* - Link_____________________Hyperlink */
} @else {
  /* Default styles for simple hyperlinks [element]
   ========================================================================== */

  a {
    display: inline-block; // usefull to apply correct line-height
    color: theme(link-txt);
    font-weight: 600;
    text-decoration: none;

    #{$interactions} {
      text-decoration: underline;
      outline: 0;
    }

    @include mq($from: S) {
      font-size: rem(14); // 14px
      line-height: lh(24, 14); // 24px
    }
  }


  // Remove default styles for links in title
  h1,
  h2,
  h3 {
    > a {
      color: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }


  // Remove default styles for links in label
  label a {
    display: inline;
  }
}
