@if $toc {
  /* - Buttons__________________Buttons */
} @else {
  /* Buttons [component]
    ========================================================================== */

  /**
  * @see  unstyled-button` mixin is defined in `./source/assets/styles/scss/tools/_tools.unstyled-button.scss`
  */

  // Common base
  .c-btn,
  .kr-smart-form .kr-embedded .kr-payment-button {
    @include unstyled-button($extend: true, $important: false);
    display: inline-block;
    padding: rem(11) rem(32); // 11px 32px
    font-weight: 600;
    letter-spacing: inherit;
    border-width: 1px;
    border-style: solid;
    transition: all var(--transition-duration);

    #{$interactions} {
      text-decoration: none;
    }

    @include mq($from: S) {
      font-size: rem(14); // 14px
      line-height: lh(24, 14); // 24px
    }
  }

  // Side by side buttons
  .c-btn + .c-btn {
    margin-left: rem(12); // 12px
  }

  // Buttons button next to .c-h3--opposite-link
  .c-h3--opposite-link + .c-btn {
    @include mq($until: S) {
      margin-bottom: rem(16); // 16px
    }
  }

  // Primary
  .c-btn--primary,
  %c-btn--primary {
    color: theme(btn--primary-txt);
    background-color: theme(btn--primary-bgc);
    border-color: theme(btn--primary-bdc);

    #{$interactions} {
      color: theme(btn--primary-txt_hover);
      background-color: theme(btn--primary-bgc_hover);
      border-color: theme(btn--primary-bdc_hover);
    }
  }

  // Secondary
  .c-btn--secondary,
  %c-btn--secondary {
    color: theme(btn--secondary-txt);
    background-color: theme(btn--secondary-bgc);
    border-color: theme(btn--secondary-bdc);

    #{$interactions} {
      color: theme(btn--secondary-txt_hover);
      background-color: theme(btn--secondary-bgc_hover);
      border-color: theme(btn--secondary-bdc_hover);
    }
  }

  // Fill
  .c-btn--fill {
    color: theme(btn--fill-txt);
    background-color: theme(btn--fill-bgc);
    border-color: theme(btn--fill-bdc);

    #{$interactions} {
      color: theme(btn--fill-txt_hover);
    }
  }

  .c-btn--small {
    padding: rem(7) rem(16);
    font-size: 0.8rem;
  }

  // Thin (button without horizontal padding)
  .c-btn--thin {
    padding-right: 0;
    padding-left: 0;
  }

  // Light
  .c-btn--light {
    padding: rem(4) rem(32) rem(2); // 4px 32px 2px
    text-transform: uppercase;

    @include mq($until: S) {
      font-size: rem(14); // 14px
      line-height: lh(24, 14); // 24px
    }

    @include mq($from: S) {
      font-size: rem(12); // 12px
      line-height: lh(24, 12); // 24px
    }
  }

  // Filter
  .c-btn--filter {
    width: 100%;
    padding-right: rem(48); // 48px
    padding-left: rem(16); // 16px
    text-align: left;
    background-image: url('../../../svg/icon/new.svg');
    background-repeat: no-repeat;
    background-position: right #{rem(16)} top 50%;
    background-size: #{rem(18)} #{rem(18)};
    border-color: theme(default-bdc);
    border-left-color: transparent;
  }

  // Pay
  .c-btn--pay {
    @extend %c-btn--primary;
    width: 100%;
    padding-right: rem(56); // 56px
    padding-left: rem(16); // 16px
    text-align: center;
    background-image: url('../../../svg/icon/pay.svg');
    background-repeat: no-repeat;
    background-position: right #{rem(12)} top 50%;
    background-size: #{rem(32)} #{rem(32)};
  }

  // Back
  .c-btn--back {
    @extend %c-btn--secondary;
    padding-right: rem(22); // 22px
    padding-left: rem(22); // 22px
  }

  // New - Favorite - Print
  .c-btn--new,
  .c-btn--favorite,
  .c-btn--print {
    padding-top: rem(7); // 7px
    padding-bottom: rem(7); // 7px
    line-height: lh(32, 16); // 32px

    @include mq($from: S) {
      line-height: lh(32, 14); // 32px
    }
  }

  // New
  .c-btn--new {
    @extend %c-btn--secondary;
    padding-right: rem(18); // 18px
    padding-left: rem(22); // 22px
  }

  // Favorite
  .c-btn--favorite {
    @extend %c-btn--secondary;
    @include font($type: semibold, $family: $defaut-font-family);
    padding-right: rem(18); // 56px
    padding-left: rem(22); // 22px
    font-size: rem(16);

    @include mq($from: S) {
      font-size: rem(14); // 14px
    }
  }

  // Print
  .c-btn--print {
    padding-right: rem(22); // 22px
    padding-left: rem(18); // 18px
    color: theme(btn--print-txt);
    background-color: theme(btn--print-bgc);
    border-color: theme(btn--print-bdc);

    #{$interactions} {
      color: theme(btn--print-txt_hover);
    }

    &:focus {
      text-decoration: underline;
    }
  }

  // Icons
  .c-btn__icon {
    position: relative;
    bottom: rem(3); // 3px
    width: rem(28); // 28px
    height: rem(28); // 28px

    .c-btn--back & {
      width: rem(10); // 10px
      height: rem(10); // 10px
      margin-right: rem(12); // 12px
    }

    .c-btn--new &,
    .c-btn--favorite & {
      margin-left: rem(12); // 12px
    }

    .c-btn--favorite & {
      width: rem(24); // 24px
      height: rem(24); // 24px
    }

    .c-btn--print & {
      margin-right: rem(12); // 12px
    }
  }

  // Disabled
  [disabled] {
    // Default
    &.c-btn {
      cursor: default;
      opacity: 0.4;
    }

    // Primary
    &.c-btn--primary {
      opacity: 0.3;

      #{$interactions} {
        background-color: theme(btn--primary-bgc);
        border-color: theme(btn--primary-bdc);
      }
    }

    &.c-btn--sticky-cart {
      opacity: 1;
      background-color: theme(default-bdc);
      border-color: theme(default-bdc);
    }

    // Secondary
    &.c-btn--secondary {
      #{$interactions} {
        color: theme(btn--secondary-txt);
        background-color: theme(btn--secondary-bgc);
        border-color: theme(btn--secondary-bdc);
      }
    }

    // Fill
    &.c-btn--fill {
      #{$interactions} {
        color: theme(btn--fill-txt);
      }
    }
  }

  // Buttons in modals
  .center-modal__wrapper .c-btn {
    margin: rem(16) rem(8) 0; // 16px 8px 0 8px
  }

  // Sticky buttons on mobile
  // @TODO: Update position
  @include mq($until: S) {
    .c-btn--sticky-cart,
    .c-product-information .c-btn--sticky-engraving {
      position: fixed;
      right: 0;
      z-index: 666;
      padding-right: 0;
      padding-left: 0;

      &:focus {
        color: theme(btn--sticky-engraving-txt_hover);
      }
    }

    // Add to cart
    .c-btn--sticky-cart {
      bottom: rem(60); // 60px (=c-shortcut's height)
      width: calc(50% + #{rem(16)});
    }

    [class].c-btn.c-btn--sticky-cart {
      margin-bottom: 0;
    }

    // Add an engraving
    .c-product-information .c-btn--sticky-engraving {
      bottom: rem(108); // 108px : 60px + 48px (= c-shortcut + c-price's height)
      width: 100%;
      background-color: theme(btn--sticky-engraving-bgc);
    }
  }

  // Specific case
  // Button on label tag
  label.c-btn {
    /* stylelint-disable-line */
    margin-bottom: 0;

    input:checked + & {
      color: theme(btn--secondary-txt_hover);
      background-color: theme(btn--secondary-bgc_hover);
      border-color: theme(btn--secondary-bdc_hover);
    }
  }
}

// SystemPay custom form button
.kr-smart-form .kr-embedded .kr-payment-button {
  color: theme(btn--primary-txt) !important;
  background-color: theme(btn--primary-bgc) !important;
  border-color: theme(btn--primary-bdc) !important;
  text-align: center;

  span {
    @include font($type: semibold, $family: $defaut-font-family);
  }

  #{$interactions} {
    color: theme(btn--primary-txt_hover) !important;
    background-color: theme(btn--primary-bgc_hover) !important;
    border-color: theme(btn--primary-bdc_hover) !important;
  }
}
