@if $toc {
  /* - Edito____________________Editorial block */
} @else {
  /* Editorial block [component]
    ========================================================================== */

  // Edito
  .c-edito {
    text-align: center;
    border-bottom: 1px solid transparent; // Prevents the lower border of the links from being trimmed when resizing the window
  }


  // Link
  // Reset text-decoration color
  .c-edito__link {
    color: inherit;
  }


  // Illustration
  .c-edito__img {
    margin: 0 auto rem(8); // 0 auto 8px auto

    @include mq($from: S) {
      margin-bottom: rem(24); // 24px
    }
  }


  // Content
  .c-edito__content {
    margin-bottom: rem(16); // 16px
  }
}
