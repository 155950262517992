// Customize cookie bot banner
#CybotCookiebotDialog {
  scale: 90%;

  #CybotCookiebotScrollContainer {
    max-height: 10em;
    min-height: 15vh;
  }

  .CybotCookiebotDialogContentWrapper {
    * {
      font-size: 12px;
    }

    h2 {
      font-size: 15px;
    }

    .CybotCookiebotDialogBodyLevelButtonSliderWrapper
      .CybotCookiebotDialogBodyLevelButtonSlider {
      background-color: $marigold;
    }

    #CybotCookiebotDialogBodyButtonsWrapper button {
      background-color: $marigold;
      border-color: $marigold;
      padding: 5px;

      &#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
        background-color: $cod-gray;
        border-color: $cod-gray;
      }
    }

    #CybotCookiebotDialogBodyEdgeMoreDetails
      #CybotCookiebotDialogBodyEdgeMoreDetailsLink {
      color: $marigold;
    }
  }
  #CybotCookiebotDialogBodyLevelButtonsSelectPane
    .CybotCookiebotDialogBodyLevelButtonWrapper {
    padding: 0.3rem;
  }
}

#CookiebotWidget #CookiebotWidget-contents #CookiebotWidget-buttons {
  #CookiebotWidget-btn-change {
    background-color: $cod-gray;
    border-color: $cod-gray;
  }
  #CookiebotWidget-btn-withdraw {
    border-color: $cod-gray;
  }
}

@include mq($until: S) {
  // Change widget trigger from bottom to top on mobile
  #CookiebotWidget {
    bottom: auto !important;
    top: 10px;
    z-index: 2 !important;
  }

  #CybotCookiebotDialog[data-template='slideup'].CybotEdge.CybotCookiebotDialogActive {
    transform: translate(-56%, -60%);
  }
}

@media screen and (min-width: 1280px) {
  #CybotCookiebotDialog[data-template='slideup'].CybotEdge.CybotCookiebotDialogActive {
    transform: translate(-56%, -110%);
  }

  #CybotCookiebotDialog.CybotEdge
    .CybotCookiebotDialogContentWrapper
    #CybotCookiebotDialogHeader,
  #CybotCookiebotDialogPoweredbyCybot {
    width: 130px;
  }

  #CybotCookiebotDialog.CybotEdge
    #CybotCookiebotDialogFooter
    button.CybotCookiebotDialogBodyButton {
    width: 200px;
  }
}
