@if $toc {
  /* - Active filter____________Active filters */
} @else {
  /* Active filter [component]
    ========================================================================== */

  .c-active-filter {
    position: relative;
    margin-top: rem(8); // 8px
    margin-bottom: rem(16); // 16px
    padding-top: rem(4); // 4px
    border-bottom: 1px solid theme(default-bdc);

    @include mq($until: S) {
      display: none;
    }
  }


  // Title
  .c-active-filter__title {
    @extend %c-h4;
    margin-bottom: rem(16); // 16px
  }


  // Tag list
  [class].c-active-filter__list {
    padding-bottom: rem(16); // 16px
  }

  // Item
  [class].c-active-filter__item {
    margin-bottom: rem(8); // 8px
  }
}
