@if $toc {
  /* - CSS Var__________________Define colors, animation/transition times and grid characteristics */
} @else {
  /* Colors [settings]
  ========================================================================== */

  :root {
    // Default value of duration for transitions and animations
    // @see [Implementing a reduced-motion mode](https://hugogiraudel.com/2018/03/19/implementing-a-reduced-motion-mode/)
    --animation-duration: 1s;
    --transition-duration: 250ms;
  }
}

// Grid characteristics (in px)
$grid-col: 98;
$grid-gutter: 24;
$grid-gutter--us: 16;

// Definition (color)
// @note Colors are named thanks to the [Chir.ag’s Name that Color utility](http://chir.ag/projects/name-that-color/).
$alto: #d7d7d7;
$boston-blue: #3a8acb;
$black-squeeze: #f2f8fb;
$chablis: #fff5f5;
$cod-gray: #0e0e0e;
$coral-red: #fe3d3d;
$gallery: #eee;
$gray: #939393;
$hot-toddy: #ac7a07;
$marigold: #ba8d26;
$mine-shaft: #393939;
$portage: #7f9beb;
$pirate-gold: #bc8f00;
$rose-bud: #f8a9a9;
$spring-wood: #faf9f5;
$sweet-pink: #fe9e9e;
$white: #fff;

// @see [Ordre des pseudo-classes des liens](http://romy.tetue.net/love-fuck-hate)
$interactions: '&:focus, &:hover, &:active';

// Abbreviations
// - bgc => background-color
// - bgi => background-image
// - bdc => border-color
// - txt => text (color)
// - plh => placeholder (color)

$theme-colors: (
  // Common values
  brand: $marigold,
  default-bdc: $alto,
  default-bgc: $white,
  default-outline: $gray,
  default-txt: $mine-shaft,
  // Assignation [element]
  del-txt: $gray,
  form-plh: $gray,
  form-error-bdc: $sweet-pink,
  heading-txt: $cod-gray,
  input-focus-bdc: $mine-shaft,
  ins-txt: $marigold,
  label__span-txt: $gray,
  link-txt: $marigold,
  mark-bgc: $black-squeeze,
  mark-txt: $boston-blue,
  selection-bgc: $marigold,
  selection-txt: $white,
  // Assignation [component]
  added-to-cart__gift-bgc: $spring-wood,
  address--light-txt: $gray,
  background-block-bgc: $spring-wood,
  disclosure--basket-summary-txt: $marigold,
  breadcrumb-bgc: $black-squeeze,
  breadcrumb__link-txt: $gray,
  breadcrumb__link-txt_current: $mine-shaft,
  breadcrumb--invert__link-txt: $white,
  btn--primary-bdc: $cod-gray,
  btn--primary-bgc: $cod-gray,
  btn--primary-txt: $white,
  btn--primary-bdc_hover: $mine-shaft,
  btn--primary-bgc_hover: $mine-shaft,
  btn--primary-txt_hover: $white,
  btn--secondary-bdc: $marigold,
  btn--secondary-bgc: transparent,
  btn--secondary-txt: $marigold,
  btn--secondary-bdc_hover: $marigold,
  btn--secondary-bgc_hover: $marigold,
  btn--secondary-txt_hover: $white,
  btn--fill-bdc: transparent,
  btn--fill-bgc: transparent,
  btn--fill-txt: $marigold,
  btn--fill-txt_hover: $hot-toddy,
  btn--print-bdc: $spring-wood,
  btn--print-bgc: $spring-wood,
  btn--print-txt: $marigold,
  btn--print-txt_hover: $hot-toddy,
  btn--sticky-engraving-bgc: $spring-wood,
  btn--sticky-engraving-txt_hover: $marigold,
  cart-summary-bdc: $spring-wood,
  cart-total__amount-txt: $marigold,
  cart-total__delivery-cost-txt: $gray,
  contextual-information-txt: $gray,
  cover-light-txt: $pirate-gold,
  cover--image-txt: $white,
  cover--image__after-bgc: $cod-gray,
  datepickr__months-bgc: $marigold,
  datepickr__months-txt: $white,
  datepickr__selected-bgc: $cod-gray,
  datepickr__selected-txt: $white,
  datepickr__today-bgc: $gallery,
  error-bgc: $spring-wood,
  faq-nav-bgc: $spring-wood,
  faq-nav__btn-txt: $gray,
  feature-txt: $marigold,
  filter__selection-bgc: $marigold,
  filter__selection-txt: $white,
  flash-sale-bgc: $spring-wood,
  flash-sale-txt: $marigold,
  footer__legal-bgc: $gallery,
  form--delivery__legend-txt: $marigold,
  form--delivery__fieldset-bdc: $spring-wood,
  h4--dark-bgc: $black-squeeze,
  header-bgc: $white,
  label--checkbox__after-bdc: $white,
  loyalty-score-bgc: $spring-wood,
  material--gold-txt: $marigold,
  material--rose-gold-txt: $rose-bud,
  material--white-gold-txt: $gray,
  material--nacre-txt: $portage,
  material--silver-txt: $gray,
  media__title-txt: $marigold,
  media__illustration__after-bgc: $marigold,
  message--error-bgc: $chablis,
  message--error-txt: $coral-red,
  message--help-bgc: $black-squeeze,
  message--help-txt: $boston-blue,
  message-view__me-bgc: $spring-wood,
  message-view__timestamp-txt: $gray,
  modal-overlay-bgc: $cod-gray,
  newsletter-bgc: $spring-wood,
  newsletter-txt: $marigold,
  newsletter__social-link-bgc: $marigold,
  newsletter__social-link-txt: $white,
  newsletter__social-link-txt_hover: $hot-toddy,
  order-summary-th__bgc: $spring-wood,
  pagination__link-txt_current: $marigold,
  pagination__link-txt_hover: $marigold,
  pagination__link--previous-bdc: $marigold,
  pagination__link--previous-bgc_hover: $marigold,
  payment-mode-txt: $gray,
  photo__after-bgc: $cod-gray,
  photo__content-txt: $white,
  progressbar-txt: $mine-shaft,
  reassurance-bgc: $black-squeeze,
  scrollbar-thumb-bgc: $cod-gray,
  scrollbar-track-bgc: $gallery,
  select--quantity-txt: $marigold,
  sale-type-txt: $marigold,
  shortcut-product-txt: $white,
  shortcut-bgc: $spring-wood,
  slider__bullet-bgc: $marigold,
  slider--dark-bgc: $black-squeeze,
  slider__thumbnail_active-bdc: $marigold,
  sponsorship__manual-bgc: $spring-wood,
  tablist__link-txt: $gray,
  tablist__link-txt_current: $marigold,
  tablist__link-txt_hover: $marigold,
  tag--highlight-bgc: $spring-wood,
  tag--highlight-txt: $marigold,
  tag--remove-focus-bdc: $mine-shaft,
  voucher-txt: $marigold,
  voucher-bgc: $spring-wood,
  // Assignation [utility]
  background-dark-bgc: $black-squeeze,
  background-light-bgc: $spring-wood
);
