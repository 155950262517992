p.env-banner {
  width: 100%;
  text-align: center;
  color: white;
  background-color: orange;
  line-height: 2;
  height: 2rem;
  padding: 0;
  margin: 0;
  top: 0;
  position: fixed;
  z-index: 1033;

  a {
    font: inherit;
    font-weight: normal;
    color: inherit;
    text-decoration: underline;
  }
}

@media screen and (max-width: 979px) {
  p.env-banner {
    position: static;

    a {
      display: none;
    }
  }
}

@media screen and (min-width: 980px) {
  body.env-banner {
    padding-top: 2rem;

    #menu {
      top: 2rem;
    }

    #react-admin-layout {
      div[class*='Sidebar-blackBackground'] {
        margin-top: 40px;
      }
    }
  }
}
