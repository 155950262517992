@if $toc {
  /* - iOS___________________Bugfixs for iOS Safari */
} @else {
  /* Bugfixs for iOS Safari [utility]
    ========================================================================== */

  /**
    * - The `-webkit-touch-callout` is a Safari Mobile implementation only.
    *
    * @see
    * [-webkit-touch-callout](https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-touch-callout)
  *
  */

  @supports (-webkit-touch-callout: none) {
    // Nav illustration item (first level)
    ._c-nav__sub-item--illustration {
      bottom: rem(72); // 72px
    }
  }
}
