@if $toc {
  /* - Rating__________________Rating system */
} @else {
  /* Rating system [component]
    ========================================================================== */

  .c-rating {
    display: flex;
    align-items: center;
    margin-bottom: rem(8); // 8px
  }

  // Stars
  .c-rating__stars {
    position: relative;
    min-height: rem(24); // 24px
    padding-left: rem(108); // 108px

    // Background: empty and full stars
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: rem(20); // 20px
      background-repeat: repeat-x;
      background-position: 0 50%;
      background-size: rem(20) auto; // 20px
    }

    // Empty stars
    &::before {
      width: rem(100); // 100px
      background-image: url('../../../../svg/icon/rating-off.svg');
    }

    // Full stars
    &::after {
      background-image: url('../../../../svg/icon/rating-on.svg');
    }
  }

  $number: (1, 2, 3, 4);
  @each $integer in $number {
    @for $decimal from 0 through 9 {
      .c-rating__stars--#{$integer}#{$decimal}::after {
        $width: 20 * $integer + 20 * calc($decimal / 10);
        width: rem($width);
      }
    }
  }

  .c-rating__stars--50::after {
    width: rem(100); // 100px
  }

  // Number of user reviews
  .c-rating__review {
    display: inline-block;
    margin-right: rem(8); // 8px

    &::before {
      content: '(';
    }

    &::after {
      content: ')';
    }
  }
}
