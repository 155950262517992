@if $toc {
  /* - Card_____________________Product card */
} @else {
  /* Product card [component]
    ========================================================================== */

  /* autoprefixer grid: autoplace */

  // Grid
  .c-card {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'thumbnail'
      'content';
  }

  // Grid areas
  .c-card__thumbnail {
    grid-area: thumbnail;
  }

  .c-card__content {
    grid-area: content;
  }

  // Content
  [class].c-card__content {
    margin-bottom: 0;
    padding-top: rem(8); // 8px
    text-align: center;

    @include mq($from: S) {
      padding-right: rem(8); // 8px
      padding-left: rem(8); // 8px
    }

    // Context-specific rule
    // Display of 2 items side by side on mobile
    @include mq($until: S) {
      word-wrap: anywhere;

      .o-layout-wrap &,
      .c-featured-product & {
        line-height: lh(21, 16); // 21px
      }

      &.c-h4 {
        font-size: 1rem;
      }
    }
  }

  // Name
  .c-card__name {
    display: block;
    margin-bottom: rem(2); // 2px
    // Reset link styles
    color: inherit;
    font-size: inherit;
    line-height: inherit;

    @include mq($from: S) {
      margin-bottom: rem(8); // 8px
    }

    // Context-specific rule
    // Display of 2 items side by side on mobile
    @include mq($until: S) {
      .o-layout-wrap &,
      .c-featured-product & {
        margin-bottom: rem(8); // 8px
      }
    }
  }

  // Tags
  .c-tag--mark {
    // Context-specific rule
    // Display of 2 items side by side on mobile
    @include mq($until: S) {
      .o-layout-wrap &,
      .c-featured-product & {
        margin-top: rem(8); // 8px
      }
    }

    // Opposite slider display
    @include mq($from: S) {
      .c-slider--opposite & {
        margin-top: rem(8); // 8px
      }
    }
  }

  // Measurement
  .c-card__measurement {
    @include font($type: regular, $family: $defaut-font-family);
    margin-top: rem(8); // 8px
    margin-bottom: rem(8); // 8px
    font-size: rem(16); // 16px
    line-height: lh(24, 16); // 24px

    @include mq($from: S) {
      font-size: rem(14); // 16px
      line-height: lh(24, 14); // 24px
    }
  }

  // Thumbnail
  .c-card__thumbnail {
    position: relative;
    background-color: theme(default-bgc);
    border: 1px solid theme(default-bdc);

    &::before {
      content: '';
      float: left;
      padding-top: 100%; // Gives a ratio of 1
    }
  }

  // Preview
  .c-card__preview {
    img {
      position: absolute;
      display: inline-block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      width: 100%;
      transition: opacity 0.5s ease-in-out;
    }

    .img-hover img {
      opacity: 0;
    }

    &:hover,
    &:focus {
      .img-main img {
        opacity: 0;
      }
      .img-hover img {
        opacity: 1;
      }
    }
  }
}
