@if $toc {
  /* - Reset____________________A pared back reset to remove margins */
} @else {
  /* Reset
   ========================================================================== */

  /**
  * A very simple reset that sits on top of Normalize.css.
  * @see [A Modern CSS Reset](https://hankchizljaw.com/wrote/a-modern-css-reset/#heading-breaking-it-down)
  */

  // Remove pointer-events within buttons
  // @see [Be Slightly Careful with Sub Elements of Clickable Things](https://css-tricks.com/slightly-careful-sub-elements-clickable-things/)
  button > * {
    pointer-events: none;
  }

  // Remove list styles and default padding on ul and ol elements with a class attribute
  ul[class],
  ol[class] {
    padding: 0;
    list-style: none;
  }

  // Remove default margin
  body,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  // A elements that don't have a class get default styles
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  // Remove the gap between audio, canvas, iframes, images, videos and the bottom of their containers:
  // @see [Vertical align media elements](https://github.com/h5bp/html5-boilerplate/issues/440)
  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
  }

  // Make images easier to work with
  img {
    display: block;
    max-width: 100%;
    height: auto;

    // Make resamplings look crisp
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  }

  // Inherit fonts for inputs and buttons
  input,
  button,
  textarea,
  select {
    font: inherit;
  }
}
