@if $toc {
  /* - Product information__________________Product information */
} @else {
  /* Product information [component]
    ========================================================================== */

  .c-product-information {
    @include mq($until: S) {
      margin-top: rem(20); // 20px
      padding-right: rem(16); // 16px
      padding-left: rem(16); // 16px
    }

    @include mq($from: S) {
      font-size: rem(14); // 14px
      line-height: lh(24, 14); // 24px
    }

    @include mq($from: M) {
      padding-right: rem(32); // 32px
      padding-left: rem(32); // 32px
    }

    @include mq($from: L) {
      padding-right: rem(64); // 64px
      padding-left: rem(64); // 64px
    }
  }

  // Wrapper
  [class].c-product-information__wrapper {
    @include mq($from: S) {
      display: block;
      $max-width: $grid-col * 4.5 + $grid-gutter * 4;
      max-width: rem($max-width);
    }
  }

  // Product title
  .c-product-information__title {
    @extend %c-h2;
  }

  // Product number
  .c-product-information__reference {
    margin-bottom: rem(16); // 16px

    // Unstyle brand link
    a {
      font-weight: normal;
      color: black;
    }

    @include mq($from: S) {
      margin-bottom: rem(24); // 24px
    }
  }

  // Delivery date
  .c-product-information__delivery {
    margin-bottom: rem(24); // 24px
    font-weight: bold;

    @include mq($from: S) {
      margin-bottom: rem(40); // 40px
    }
  }

  #js-modal-guide-link {
    text-align: center;
  }

  #alma-widget {
    margin-top: 1rem;
    border: 1px solid #d7d7d7;
  }

  .c-product--description {
    @include mq($from: S) {
      border-top: 1px solid theme(default-bdc);
      padding: rem(48) rem(24) 0; // 48px 24px 72px 24px
    }
  }
}
