@if $toc {
  /* - Backgrounds______________Add background color */
} @else {
  /* Add background color [utility]
    ========================================================================== */

  [class].u-background-dark {
    background-color: theme(background-dark-bgc);
  }

  [class].u-background-light {
    background-color: theme(background-light-bgc);
  }

  [class].u-background-default {
    background-color: theme(default-bgc);
  }
}
