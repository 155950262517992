@if $toc {
  /* - Block____________________Block with background color */
} @else {
  /* Block with background color [component]
    ========================================================================== */

  .c-background-block {
    padding: rem(16); // 16px
    background-color: theme(background-block-bgc);

    @include mq($from: S) {
      display: flex;
      flex-direction: column;
      padding: rem(24); // 24px
    }
  }

  .c-background-block__action {
    text-align: center;

    @include mq($from: S) {
      margin-top: auto;
      text-align: right;
    }
  }
}
