@if $toc {
  /* - Reduced-motion___________Specific reset styles for reduced motion mode */
} @else {
  /* Reduced motion mode
   ========================================================================== */

  /**
  * Disable all animations and transitions and smooth scroll behavior
  * @see [Implementing a reduced-motion mode](https://hugogiraudel.com/2018/03/19/implementing-a-reduced-motion-mode/)
  * @see [Smooth scrolling links with only CSS](https://gomakethings.com/smooth-scrolling-links-with-only-css/)
  */

  @media (prefers-reduced-motion: reduce) {
    :root {
      --animation-duration: 0;
      --transition-duration: 0;
    }

    html {
      scroll-behavior: auto;
    }
  }
}
