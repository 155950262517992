@if $toc {
  /* - Form____________________Delivery forms */
} @else {
  /* Delivery forms [component]
    ========================================================================== */

  // Form
  .c-form {
    background-color: theme(default-bgc);
  }


  // Legend
  .c-form__legend {
    text-align: center;
    background-color: inherit;
  }



  // Specific case
  // Delivery form
  .c-form--delivery {
    .c-form__fieldset {
      position: relative; // Usefull to .c-form__fieldset::after
      margin-bottom: rem(16); // 16px
      padding: 0 rem(16) rem(16); // 0 16px 16px 16px

      @include mq($from: S) {
        margin-bottom: rem(8); // 8px
        padding: 0 0 rem(32); // 0 0 32px 0
      }
    }

    // Visual separation between fieldset
    .c-form__fieldset::after {
      content: "";
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      height: rem(16); // 16px
      background-color: theme(form--delivery__fieldset-bdc);

      @include mq($from: S) {
        height: rem(8); // 8px
      }
    }

    // Legend
    .c-form__legend {
      padding: rem(16) 0; // 16px 0 16px
      font-weight: 600;
      color: theme(form--delivery__legend-txt);

      @include mq($from: S) {
        padding-top: rem(32); // 32px
      }
    }

    // Same as delivery checkbox
    .c-form__same-as {
      text-align: center;

      .c-label {
        display: inline-block;
      }

      // The form is displayed
      [aria-expanded="true"] + .c-label {
        margin-bottom: rem(8); // 8px

        @include mq($from: S) {
          margin-bottom: rem(16); // 16px
        }
      }
    }

    // Form wrapper
    .c-form__wrapper {
      &[hidden] {
        display: none;
      }

      @include mq($from: S) {
        margin-right: auto;
        margin-left: auto;
        padding-right: rem(16); // 16px
        padding-left: rem(16); // 16px
        $max-width: 6 * $grid-col + 5 * $grid-gutter;
        max-width: rem($max-width); // 708px
      }
    }

    // Invoice details
    .c-form__invoice {
      margin-top: rem(16); // 16px
      width: 100%;
      font-size: rem(14); // 14px
      line-height: lh(21, 14); // 21px
    }

    // Personalized message
    .c-form__add-message {
      font-weight: 600;
      margin-bottom: rem(16); // 16px

      @include mq($from: S) {
        margin-bottom: rem(8); // 8px
      }
    }

    // Number of characters/lines still to be entered
    .c-form__textarea-countdown {
      @include mq($until: S) {
        display: block;

        &::before {
          content: normal;
        }
      }
    }

    // Go to button
    .c-form__next-step {
      padding: rem(32) 0; // 32px 0
      text-align: center;

      @include mq($until: S) {
        position: fixed;
        z-index: 666;
        right: 0;
        bottom: 0;
        left: 0;
        padding: rem(8) rem(16); // 8px 16px
        margin: 0;
        background-color: theme(default-bgc);
      }
    }
  }
}
