@if $toc {
  /* - block____________________Line break */
} @else {
  /* Line break [utility]
    ========================================================================== */

  // Block
  .u-block {
    display: block;
  }


  // Responsive class
  .u-block\@us {
    @include mq($until: S) {
      display: block;
    }
  }

  .u-block\@fm {
    @include mq($from: M) {
      display: block;
    }
  }
}
