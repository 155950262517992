@if $toc {
  /* - Selection________________Selection (*::selection) */
} @else {
  /* Selection [element]
   ========================================================================== */

  /**
  * Remove text-shadow in and customize selection highlight.
  * @see <https://twitter.com/miketaylr/status/12228805301>
  */

  ::selection {
    color: theme(selection-txt);
    text-shadow: none;
    background: theme(selection-bgc);
  }
}
