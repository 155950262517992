@if $toc {
  /* - Space____________________Adds margin and padding */
} @else {
  /* Adds margin and padding [utility]
    ========================================================================== */

  $spaces: 8, 16, 24, 32, 40, 56;

  @each $space in $spaces {
    $step: calc($space / 8);

    [class].u-mb-#{$step} {
      margin-bottom: rem($space);
    }
  }

  // Specific cases
  [class].u-mb-05 {
    margin-bottom: rem(4); // 4px
  }

  [class].u-mr-2 {
    display: inline-block;
    margin-right: rem(16); // 16px
  }

  // Responsive class
  $us-spaces: 8, 16, 24, 32, 40, 48, 56, 80; /* stylelint-disable-line */
  $fs-spaces: 8, 16, 24, 32, 40, 48, 56, 64, 72, 88, 112, 128; /* stylelint-disable-line */

  @include mq($until: S) {
    [class].u-lateral-2\@us {
      padding-right: rem(16); // 16px
      padding-left: rem(16); // 16px
    }

    [class].u-pb-3\@us {
      padding-bottom: rem(24); // 24px
    }

    [class].u-pb-5\@us {
      padding-bottom: rem(40); // 40px
    }

    [class].u-pb-10\@us {
      padding-bottom: rem(80); // 80px
    }

    @each $space in $us-spaces {
      $step: calc($space / 8);

      [class].u-mb-#{$step}\@us {
        margin-bottom: rem($space);
      }
    }
  }

  @include mq($from: S) {
    [class].u-pb-7\@fs {
      padding-bottom: rem(56); // 56px
    }

    [class].u-pb-8\@fs {
      padding-bottom: rem(64); // 64px
    }

    [class].u-pb-14\@fs {
      padding-bottom: rem(112); // 112px
    }

    @each $space in $fs-spaces {
      $step: calc($space / 8);

      [class].u-mb-#{$step}\@fs {
        margin-bottom: rem($space);
      }
    }
  }
}
