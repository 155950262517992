@if $toc {
  /* - Product image__________________Product image */
} @else {
  /* Product image [component]
    ========================================================================== */

  /*
  IE will not automatically size (or hide) product images because it doesn't
  support `srcset`and, more importantly, `sizes` attributes.  So we need these
  to enforce sizing properly.  This has to be kept in sync with
  `ImagesConfiguration::PRODUCT_SERVING_SIZES`.  These classes are added by the
  `product_picture_tag` helper.
  */

  .c-product-image {
    // So alt texts don't wreck the layout
    overflow: hidden;
  }

  .c-product-image--cart {
    width: rem(108);
    @include mq($until: S) {
      display: none; // Desktop only
    }
  }
  .c-product-image--main {
    width: rem(464);
    @include mq($until: S) {
      width: rem(704);
    }
  }
  .c-product-image--search {
    width: rem(292);
    @include mq($until: S) {
      width: rem(344);
    }
  }
  .c-product-image--selection {
    width: rem(292);
    @include mq($until: S) {
      width: rem(688);
    }
  }
  .c-product-image--cart {
    width: rem(85);
  }
  .c-product-image--summary {
    width: rem(92);
    @include mq($until: S) {
      display: none; // Desktop only
    }
  }
  .c-product-image--thumb {
    width: rem(84);
    @include mq($until: S) {
      display: none; // Desktop only
    }
  }
  .c-product-image--widget-carousel {
    width: rem(177);
  }
}
