@if $toc {
  /* - Overflow_________________Add overflow property */
} @else {
  /* Add overflow property [utility]
    ========================================================================== */

  [class].u-overflow {
    overflow: hidden; // Disable margin-collapsing
  }

  [class].u-overflow--none {
    overflow: visible;
  }

  // Hide the scrollbar without disabling the scrolling feature
  [class].u-overflow--auto {
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
