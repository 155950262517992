@if $toc {
  /* - position_________________Gives relative position */
} @else {
  /* Gives relative position [utility]
    ========================================================================== */

  [class].u-position {
    position: relative;
  }
}
