@if $toc {
  /* - Dialog___________________Dialog <dialog> (modal) */
} @else {
  /* Dialog [element]
    ========================================================================== */

  dialog {
    display: block;
    margin: 0;
    padding: 0;
    color: inherit;
    border: 0;
  }
}
