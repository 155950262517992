@if $toc {
  /* - Notice____________________Notice and description */
} @else {
  /* Notice and description [component]
    ========================================================================== */

  // Wrapper
  .c-notice {
    @include mq($until: S) {
      border-bottom: 1px solid theme(default-bdc);
    }

    @include mq($from: S) {
      position: relative; // Necessary for button positioning
      padding-top: rem(56); // 64px
      padding-left: rem($grid-gutter);
    }

    @include mq($from: M) {
      $width: calc(($grid-gutter + $grid-col) / 2);
      padding-left: rem($width);
    }

    @include mq($from: L) {
      $width: $grid-gutter + $grid-col;
      padding-left: rem($width);
    }
  }

  // Action button
  @include mq($from: S) {
    .c-notice__btn {
      $width: $grid-gutter + $grid-col;
      $left: $grid-gutter;
      width: auto;
      position: absolute;
      top: 0;
      left: rem($left);

      &:not(:first-of-type) {
        $width: $left + $width;
        left: rem($width);
      }

      // Current page
      &[aria-expanded='true']::after {
        content: '';
        position: absolute;
        top: auto;
        right: rem(-2); // -2px
        bottom: rem(-8); // -8px
        left: rem(-2); // -2px
        height: 1px;
        width: auto;
        background-color: currentColor;
        transform: none;
      }
    }
  }

  @include mq($from: M) {
    .c-notice__btn {
      $width: $grid-gutter + $grid-col;
      $left: calc($width / 2);
      left: rem($left);

      &:not(:first-of-type) {
        $width: $left + $width;
        left: rem($width);
      }
    }
  }

  @include mq($from: L) {
    .c-notice__btn {
      $width: $grid-gutter + $grid-col;
      left: rem($width);

      &:not(:first-of-type) {
        $width: $width * 2;
        left: rem($width);
      }
    }
  }
}
