@if $toc {
  /* - Cover____________________Homepage headers */
} @else {
  /* Homepage headers [component]
    ========================================================================== */

  // Content
  .c-cover__content {
    padding: rem(16);
    text-align: center;

    @include mq($from: S) {
      padding-top: 0;
      padding-bottom: rem(40); // 40px
    }

    @include mq($from: L) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  // Title
  [class].c-cover__title {
    margin-bottom: 0;

    .u-background-light & {
      color: theme(cover-light-txt);
    }

    .c-cover--large &,
    .c-cover--medium & {
      color: inherit;
    }

    &::first-letter {
      text-transform: uppercase;
    }

    @include mq($until: S) {
      &.c-h3 {
        font-weight: normal;
      }
    }
  }

  // Decorative stars
  .c-cover__title--star {
    &::before,
    &::after {
      content: '';
      display: inline-block;
      width: rem(32); // 32px
      height: rem(32); // 32px
      vertical-align: text-bottom;
      background: url('../../../svg/icon/stars.svg')
        50%
        50% /
        #{rem(32)}
        #{rem(32)}
        no-repeat;
    }

    $list: (
      'before': 'right',
      'after': 'left',
    ); /* stylelint-disable-line */
    @each $pseudo-element, $side in $list {
      &::#{$pseudo-element} {
        margin-#{$side}: rem(12); // 12px

        @include mq($from: S) {
          margin-#{$side}: rem(40); // 40px
        }
      }
    }

    .u-background-dark &::before,
    .u-background-dark &::after {
      background-image: url('../../../svg/icon/stars-blue.svg');
    }
  }

  // Description
  .c-cover__description {
    margin-top: rem(16); // 16px

    @include mq($from: M) {
      max-width: rem(1218); // 1218px = 1074 + 72 * 2
      margin-right: auto;
      margin-left: auto;
    }

    @include mq($from: L) {
      @include fixed-width($item: 9);
    }
  }

  // Specific case
  // Homepage & theme homepage cover
  .c-cover--large,
  .c-cover--medium {
    position: relative; // Usefull to ::after
    color: theme(cover--image-txt);

    // Background opacity
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1; // Push background under content
      // This has been commented to prevent picture darkening
      // background-color: transparentize(theme(cover--image__after-bgc), 0.7);
    }

    // Content
    .c-cover__content {
      position: absolute;
      top: 50%;
      width: 100%;
      padding-bottom: 0;
      color: theme(cover--image-txt);
    }

    // Illustration
    .c-cover__illustration {
      position: relative;
      z-index: -2;
    }
  }

  // Theme homepage cover
  .c-cover--medium {
    margin-top: rem(-40); // Places the image below the breadcrumb
    margin-bottom: rem(16); // 16px

    @include mq($from: S) {
      margin-top: rem(-48); // Places the image below the breadcrumb
      margin-bottom: rem(48); // 48px
    }
  }

  .c-cover--medium {
    .c-breadcrumb {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .c-cover__content {
      left: 50%;
      transform: translate(-50%, -50%);

      @include mq($from: S) {
        padding-right: rem(72); // 72px
        padding-left: rem(72); // 72px
      }
    }
  }

  // Homepage cover
  .c-cover--large {
    margin-bottom: rem(24); // 24px

    @include mq($from: S) {
      margin-bottom: rem(48); // 48px
    }
  }

  .c-cover--large {
    .c-cover__content {
      transform: translateY(30%);

      @include mq($until: S) {
        transform: translateY(50%);
      }

      @include mq($from: S) {
        left: rem(64); // 64px
        width: calc(100% - #{rem(128)}); // 128px = 64 * 2
        text-align: left;
      }

      @include mq($from: M) {
        @include fixed-width($item: 6);
      }
    }

    .c-cover__title {
      margin-bottom: rem(16); // 16px

      @include mq($from: S) {
        margin-bottom: rem(24); // 24px
      }
    }
  }

  // Search page cover
  .c-cover--search .c-cover__content {
    @include mq($from: S) {
      padding-top: rem(40); // 40px
      padding-bottom: rem(80); // 80px
    }
  }
}

.widget .c-cover__title {
  font-size: 1.75rem;

  @include mq($from: S) {
    font-size: 3.25rem;
  }
}
