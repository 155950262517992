@if $toc {
  /* - Confirmation_____________Order confirmation */
} @else {
  /* Order confirmation [component]
    ========================================================================== */

  // Root element
  .c-confirmation {
    padding-top: rem(24); // 24px

    @include mq($from: S) {
      padding-top: rem(72); // 72px
    }
  }

  // Content
  .c-confirmation__content {
    padding-top: rem(88); // 88px
    background: url('../../../../svg/icon/confirmation.svg')
      50%
      0 /
      #{rem(72)}
      #{rem(72)}
      no-repeat;

    @include mq($from: S) {
      background-position: 0 0;
    }
  }

  // Title
  .c-confirmation__title {
    @extend %c-h3;
    margin-bottom: rem(8); // 8px

    @include mq($from: S) {
      margin-bottom: rem(16); // 16px
    }
  }

  // Buttons wrapper
  .c-confirmation__wrapper {
    @include mq($until: S) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  [class].c-confirmation__wrapper .c-btn--primary {
    @include mq($until: S) {
      margin-bottom: rem(16); // 16px
      margin-left: 0;
    }
  }
}
