/* Visually hidden [mixin]
  ========================================================================== */

/**
 * Hide only visually, but have it available for screen readers.
 * @include visually-hidden($extend, $important);
 *
 * @author Gaël Poupard
 * @see [Cache-cache CSS](https://www.ffoodd.fr/cache-cache-css/)
 * | Parameter   | Type | Value | Description                                                    |
 * |-------------|------|-------|----------------------------------------------------------------|
 * |`$extend`    | bool | true  | extend the placeholder or dump the CSS code as a regular mixin |
 * |`$important` | bool | false | (optional) add `!important` declaration to each rule           |
 *
 * .usage {
 *		@include visually-hidden($extend: true, $important: false);
 *	}
 */


@mixin visually-hidden($extend: true, $important: false) {

  @if ($extend != true and $extend != false) {
    @error "`#{$extend}` needs to be `true` or `false`.";
  }

  @if ($important == true) {
    $important: !important;
  } @else if ($important == false) {
    $important: null;
  } @else {
    @error "`#{$important}` needs to be `true` or `false`.";
  }

  @if $extend {
    @extend %o-visually-hidden;
  } @else {
    position: absolute $important;
    width: 1px $important;
    height: 1px $important;
    margin: -1px $important;
    padding: 0 $important;
    overflow: hidden $important;
    white-space: nowrap $important;
    border: 0 $important;
    clip: rect(1px, 1px, 1px, 1px) $important;
    clip-path: inset(50%) $important;
  }
}
