@if $toc {
  /* - Heading__________________Heading (h1 -> h6) */
} @else {
  /* Heading [component]
   ========================================================================== */

  .c-h1,
  .c-h2,
  .c-h3 .c-h4 {
    @include font($type: bold, $family: $serif);
    display: block;
    margin: 0;
    color: theme(heading-txt);
  }

  .c-h1,
  .c-h2 {
    margin-bottom: rem(16); // 16px
  }

  .c-h3,
  .c-h4,
  .c-h5 {
    margin-bottom: rem(8); // 8px
  }

  .c-h1,
  %c-h1 {
    font-size: rem(34); // 34px
    line-height: lh(44, 34); // 44px

    @include mq($from: S) {
      font-size: rem(52); // 52px
      line-height: lh(64, 52); // 64px
      letter-spacing: 1.5px;
    }
  }

  .c-h2,
  %c-h2 {
    font-size: rem(24); // 24px
    line-height: lh(34, 24); // 34px

    @include mq($from: S) {
      font-size: rem(34); // 34px
      line-height: lh(46, 34); // 46px
      letter-spacing: 1px;
    }
  }

  .c-h3,
  %c-h3 {
    font-size: rem(20); // 20px
    line-height: lh(24, 20); // 24px

    @include mq($from: S) {
      font-size: rem(24); // 24px
      line-height: lh(32, 24); // 32px
    }
  }

  .c-h4,
  %c-h4 {
    @include font($type: bold, $family: $serif);
    position: relative; // Usefull to .c-h4__link
    font-size: rem(18); // 18px
    line-height: lh(24, 18); // 24px
  }

  // Link in title
  .c-h4__link {
    @include font($type: regular, $family: $sans-serif);
    position: absolute;
    top: rem(12); // 12px
    right: rem(16); // 16px
    font-size: rem(14); // 14px
    line-height: lh(24, 14); // 21px

    @include mq($from: S) {
      top: rem(16); // 16px
      right: rem(32); // 32px
    }
  }

  .c-h5 {
    @include font($type: semibold, $family: $defaut-font-family);
    font-size: rem(16); // 16px
    line-height: lh(24, 16); // 24px

    @include mq($from: S) {
      font-size: rem(14); // 14px
      line-height: lh(20, 14); // 20px
    }
  }

  // Variations
  .c-h3--light {
    color: theme(brand);
  }

  // With decorative star
  .c-h3--star {
    margin: rem(16) 0; // 16px 0
    padding-left: rem(32); // 32px
    background: url('../../../svg/icon/stars.svg')
      0
      50% /
      #{rem(24)}
      #{rem(24)}
      no-repeat;

    @include mq($from: S) {
      margin: rem(40) 0 rem(48); // 40px 0 48px 0
      padding-left: rem(36); // 36px
    }
  }

  // With opposite link
  .c-h3--opposite-link {
    @include mq($until: S) {
      margin-bottom: rem(16); // 16px
    }

    @include mq($from: S) {
      display: inline-block;
      margin-right: rem(16); // 16px
    }
  }

  // Dark title
  .c-h4--dark {
    display: block;
    padding: rem(12) rem(16); // 12px 16px
    background-color: theme(h4--dark-bgc);

    @include mq($from: S) {
      padding: rem(16) rem(32); // 16px 32px
    }

    &:last-child {
      /* stylelint-disable-line */
      margin-bottom: 0;
    }
  }

  // Thick (and dark) title
  .c-h4--thick {
    margin-bottom: 0;

    @include mq($from: S) {
      padding: rem(28) rem(32); // 28px 32px
    }

    .c-h4__link {
      /* stylelint-disable-line */
      top: rem(28); // 28px

      @include mq($until: S) {
        position: static;
        display: block;
        margin-top: rem(4); // 4px
      }
    }
  }

  // Title with counter
  .c-h4--counter {
    counter-increment: step; // Increment the value of step counter by 1

    @include mq($until: S) {
      margin-right: rem(-16); // -16px
      margin-left: rem(-16); // -16px
    }

    &::before {
      /* stylelint-disable-line */
      content: counter(step) '. ';
    }

    &:not(:first-child) {
      margin-top: rem(16); // 16px
    }
  }
}
