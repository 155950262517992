@if $toc {
  /* - Tab______________________Tab list & tab panel */
} @else {
  /* Tab list & tab panel [component]
    ========================================================================== */

  // Tablist
  [class].c-tablist {
    margin-top: rem(24); // 24px
    margin-bottom: rem(24); // 24px
    line-height: lh(24, 16); // 24px
    text-transform: uppercase;

    @include mq($from: S) {
      font-size: rem(14); // 14px
      line-height: lh(24, 14); // 24px
    }
  }

  // Client space tablist
  [class].c-tablist--client-space {
    white-space: nowrap; // Force the overflow

    @include mq($from: S) {
      margin-bottom: 0;
      padding-bottom: rem(24); // 24px
      padding-left: rem(32); // 32px
      border-bottom: 1px solid theme(default-bdc);
    }
  }

  // Sticky on mobile
  [class].c-tablist--sticky {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    margin: 0;
    padding: rem(16) rem($grid-gutter--us); // 16px 16px
    background-color: theme(default-bgc);

    @include mq($from: S) {
      display: none;
    }
  }

  // Items
  .c-tablist__item:not(:last-child) {
    margin-right: rem(32); // 32px

    @include mq($from: S) {
      margin-right: rem(40); // 40px
    }
  }

  // Links
  .c-tablist__link {
    position: relative; // Usefull to ::after
    color: theme(tablist__link-txt);
    cursor: pointer;

    #{$interactions} {
      color: theme(tablist__link-txt_hover);
      text-decoration: none;
    }

    &:focus {
      outline: 1px dotted theme(default-outline);
    }

    // Current panel
    // Current page
    // Current location
    &[aria-selected="true"],
    &[aria-current="page"],
    &[aria-current="location"] {
      color: theme(tablist__link-txt_current);

      &::after {
        content: "";
        position: absolute;
        right: rem(-2); // -2px
        bottom: rem(-8); // -8px
        left: rem(-2); // -2px
        height: 1px;
        background-color: currentColor;
      }
    }
  }


  // Tabpanel
  .c-tabs [aria-hidden="true"].c-tabpanel {
    display: none;
  }



  // Specific case
  // Home tabs
  .c-tabs--home {
    @include mq($from: S) {
      position: relative; // Usefull to .c-tablist
    }
  }

  // Tablist
  .c-tabs--home .c-tablist {
    @include mq($from: S) {
      position: absolute;
      top: 0;
      left: calc(50% + #{rem(36)}); // 36px = 12px + 24px
      // 12px: Half $grid-gutter
      // 24px: $grid-gutter
      width: auto;
      margin-top: 0; // 24px
      margin-bottom: 0; // 24px
    }

    @include mq($from: L) {
      left: calc(50% + #{rem(76)}); // 76px = 12px + 64px
      // 12px: Half $grid-gutter
      // 64px: Padding of .c-tabpanel__content
    }
  }


  // Illustration
  .c-tabs--home  .c-tabpanel__illustration {
    margin-bottom: rem(16); // 16px

    @include mq($from: S) {
      margin-bottom: rem(48); // 48px
    }
  }

  // Content
  .c-tabs--home .c-tabpanel__content {
    @include mq($until: S) {
      padding-bottom: rem(24); // 24px
    }

    @include mq($from: S) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin: rem(48) 0; // 48px = 32px + 16px
      // 32px: .c-tablist's height
      // 16px: add little space
      padding: 0 rem(24); // 24px
    }

    @include mq($from: L) {
      padding: 0 rem(64); // 64px
    }
  }

  // Title
  .c-tabs--home .c-tabpanel__title {
    @include mq($until: S) {
      margin-bottom: rem(8); // 8px
    }
  }

  // Text
  .c-tabs--home .c-tabpanel__text {
    margin-bottom: rem(24); // 24px

    @include mq($from: S) {
      margin-bottom: rem(32); // 32px
    }
  }



  // Specific case
  // Relay point

  // Tablist
  .c-tabs--relay-point {
    .c-tablist {
      margin-bottom: rem(8); // 8px
    }

    .c-tablist__link {
      min-width: rem(104); // 104px
      text-align: center;

      &::after {
        height: 2px;
      }
    }
  }

  // Tabpanel
  @include mq($from: M) {
    .c-tabs--relay-point {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      [aria-hidden="true"].c-tabpanel {
        display: block;
      }

      .c-tabpanel__map {
        @include ratio-width(
          $item: 4,
          $total-item: 9,
          $gutter-width: $grid-gutter--us
        );
      }

      .c-tabpanel__list {
        @include ratio-width(
          $item: 5,
          $total-item: 9,
          $gutter-width: $grid-gutter--us
        );
        position: relative;
        overflow-y: scroll;
        scrollbar-width: thin; // "auto" or "thin"
        scrollbar-color: theme(scrollbar-thumb-bgc) theme(scrollbar-track-bgc); //scroll thumb & track

        &::before {
          content: "";
          display: block;
          padding-top: 110%; // Give a ratio of 1.10
        }

        // Scrollbar definition
        &::-webkit-scrollbar {
          width: rem(8); // 8px = width of the entire scrollbar
        }

        &::-webkit-scrollbar-thumb {
          background-color: theme(scrollbar-thumb-bgc); // color of the scroll thumb
          border-radius: rem(8); // roundness of the scroll thumb
        }

        &::-webkit-scrollbar-track {
          background-color: theme(scrollbar-track-bgc); // color of the tracking area
        }
      }
    }
  }
}
