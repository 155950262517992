@if $toc {
  /* - Center___________________Center images and blocks */
} @else {
  /* Center images and blocks [utility]
    ========================================================================== */

  [class].u-center {
    margin-right: auto;
    margin-left: auto;
  }
}
