@if $toc {
  /* - Border___________________Add borders */
} @else {
  /* Add borders [utility]
    ========================================================================== */

  // Responsive class
  @include mq($until: S) {
    [class].u-border-bottom\@us {
      border-bottom: 1px solid theme(default-bdc);
    }
  }
}
