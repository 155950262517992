@if $toc {
  /* - Image like_______________Text rendered as an image */
} @else {
  /* Text rendered as an image [component]
    ========================================================================== */

  .c-img-like {
    @include font($type: bold, $family: $serif);
    @extend %c-h3;
    display: block;
    margin-right: auto;
    margin-left: auto;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
  }

  // Variations
  @include mq($until: S) {
    .c-img-like--2\/3 {
      display: flex;
      align-items: center;
      width: 100vw;
      height: calc(144 / 320) * 100vw;
      padding-right: rem(8); // 8px
      padding-left: 41%;
      background-image: url('../../../images/img-2_3--us.svg');
    }
  }

  @include mq($from: S) {
    .c-img-like--2\/3 {
      width: rem(220); // 220px
      height: rem(330); // 330px
      padding: rem(152) rem(8) rem(8); // 152px 8px 8px 8px
      text-align: center;
      background-image: url('../../../images/img-2_3--fs.svg');
    }
  }
}
