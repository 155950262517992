@if $toc {
  /* - Slider___________________Slider */
} @else {
  /* Slider [component]
    ========================================================================== */

  /**
  * @note Slider pagination is defined in components.pagination file
  */

  // Carousel rules
  // @see [Glide.js](https://glidejs.com/docs/)
  .c-glide--active {
    position: relative;
    width: 100%;
  }

  .c-glide--active .c-glide__track {
    overflow: hidden;
  }

  .c-glide--active.c-glide--swipeable .c-glide__track {
    cursor: grab;
  }

  .c-glide--active .c-glide__slides {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    padding: 0;
    overflow: hidden;
    list-style: none;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    touch-action: pan-Y;
    will-change: transform;
  }

  .c-glide--active .c-glide__slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }

  .c-glide--active .c-glide__slide a {
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  .c-glide--active .c-glide__bullet-wrapper {
    margin-top: rem(24); // 24px
    margin-right: rem(16); // 16px : Offset to show the following slide
    text-align: center;
    -webkit-touch-callout: none;
    user-select: none;

    @include mq($from: S) {
      margin-top: rem(16); // 16px
    }
  }

  .c-glide--active .c-glide__bullet {
    @include unstyled-button($extend: true, $important: false);
    width: rem(11); // 11px
    height: rem(11); // 11px
    margin: 0 rem(6); // 6px
    background-color: theme(slider__bullet-bgc);
    border-radius: 50%;
    cursor: pointer;
    opacity: 0.25;
    transition: opacity var(--transition-duration);

    &.is-active,
    #{$interactions} {
      opacity: 1;
    }

    &:focus {
      outline: 1px solid theme(default-outline);
    }
  }

  .c-glide--active .c-glide__thumbnail {
    margin-top: rem(8); // 8px
    margin-right: rem(6); // 6px
  }

  .c-glide--active .c-glide__thumbnail {
    @include unstyled-button($extend: true, $important: false);
    padding: rem(8); // 8px
    border: 1px solid theme(default-bdc);

    &.is-active,
    #{$interactions} {
      border-color: theme(slider__thumbnail_active-bdc);
    }
  }

  .c-glide__thumbnail.video,
  .c-slider__preview.video,
  .c-glide__thumbnail.audio,
  .c-slider__preview.audio {
    color: theme(btn--secondary-txt);
    vertical-align: top;
  }

  .c-glide__thumbnail.audio,
  .c-glide__thumbnail.video {
    .MuiSvgIcon-root {
      height: 84px;
      width: 84px;
    }
  }

  .c-slider__preview.video {
    position: relative;
    overflow: hidden;
  }

  .c-slider__preview.video,
  .c-slider__preview.audio {
    background-color: white;
    border: none;

    .MuiSvgIcon-root {
      height: 60%;
      width: 60%;
      margin: 20%;
    }
  }

  .c-slider__video-overlay {
    background-color: white;
    display: block;
    position: absolute;
    width: 22%;
    height: 100%;
    z-index: 1;
    top: 0;

    &.c-slider__video-overlay-left {
      left: 0;
    }
    &.c-slider__video-overlay-right {
      right: 0;
    }
  }

  @include mq($from: M) {
    .c-glide__thumbnail.video .MuiSvgIcon-root,
    .c-glide__thumbnail.audio .MuiSvgIcon-root {
      height: 50px;
      width: 50px;
      margin: 17px;
    }
  }

  // Slider
  // Common rules
  .c-slider__title {
    @extend %c-h3;
  }

  .c-slider__cta {
    margin-top: rem(24); // 24px
  }

  // Slider large
  .c-slider--large {
    padding-top: rem(24); // 24px
    padding-bottom: rem(24); // 24px
    text-align: center;

    @include mq($from: S) {
      position: relative; // Usefull to pagination
      padding-top: rem(32); // 32px
      padding-bottom: rem(40); // 40px

      .c-slider__title {
        margin-bottom: rem(32); // 32px
      }

      .c-slider__glide-wrapper {
        @include ratio-width(9, 12);
        margin: 0 auto;
      }

      .c-slider__cta {
        margin-top: rem(32); // 32px
      }
    }
  }

  // Opposite slider
  .c-slider--opposite {
    position: relative;

    @include mq($until: S) {
      margin-bottom: rem(32); // 32px
      padding-bottom: rem(72); // 72px

      .c-slider__illustration,
      .c-slider__description {
        display: none;
      }

      .c-slider__title {
        text-align: center;
      }

      .c-slider__cta-wrapper {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: center;
      }
    }

    @include mq($from: S) {
      margin-bottom: rem(48); // 48px

      .c-slider__illustration,
      .c-slider__description {
        margin-bottom: rem(16); // 16px
      }

      .c-slider__title {
        margin-bottom: rem(8); // 8px
      }

      .c-slider__cta {
        margin-top: 0;
      }

      .c-slider__glide-wrapper {
        padding-bottom: rem(64); // 64px = pagination height + 16px
      }

      .c-glide__bullet-wrapper {
        display: none;
      }
    }
  }

  // Product slider
  .c-slider--product {
    .c-glide__track {
      border: 1px solid theme(default-bdc);
    }

    .c-slider__thumbnail {
      position: relative;

      &::before {
        content: '';
        float: left;
        padding-top: 90%; // Give a ratio of .9
      }
    }

    .c-slider__preview {
      width: 100%;
      height: auto;
    }

    .c-glide__bullet-wrapper {
      position: relative;
      bottom: rem(12); // 12px
      // Sign changed for #344
      margin-top: rem(18); // 32px
      margin-right: 0;
      line-height: 1;
    }
  }
}
