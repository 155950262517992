@if $toc {
  /* - Link_____________________Links like */
} @else {
  /* Links like [component]
    ========================================================================== */

  /**
  * @see  unstyled-button` mixin is defined in `./source/assets/styles/scss/tools/_tools.unstyled-button.scss`
  */

  .c-link {
    @include unstyled-button($extend: true, $important: false);
    display: inline-block; // usefull to apply correct line-height
    color: theme(link-txt);
    font-weight: 600;
    letter-spacing: inherit;
    text-decoration: none;

    #{$interactions} {
      text-decoration: underline;
    }

    @include mq($from: S) {
      font-size: rem(14); // 14px
      line-height: lh(24, 14); // 24px
    }
  }

  // Link with icon
  // Go back link
  .c-link--icon,
  .c-link--back {
    padding-left: rem(20); // 20px
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: auto #{rem(11)};
  }

  // Right arrow
  .c-link--icon {
    background-image: url('../../../svg/icon/arrow.svg');
  }

  // Go back link
  .c-link--back {
    margin-top: rem(16); // 16px
    background-image: url('../../../svg/icon/arrow-back.svg');

    @include mq($from: S) {
      margin-top: rem(32); // 32px
    }
  }

  // Link with checkbox
  .c-link--checkbox-like {
    position: relative; // Usefull to pseudo-elements
    display: block;
    margin-bottom: rem(8); // 8px
    padding-left: rem(28); // 28px
    color: inherit;
    font-weight: 600;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0.2rem;
      left: 0;
      width: 1rem;
      height: 1rem;
      background-color: theme(default-bgc);
      border: 1px solid theme(brand);
    }

    &::after {
      top: 0.7rem;
      left: 0.125rem;
      width: 0.375rem;
      height: 0.65rem;
      background-color: theme(brand);
      border-width: 2px 2px 0 0;
      border-style: solid;
      border-color: theme(label--checkbox__after-bdc);
      transform: scaleX(-1) rotate(135deg);
      transform-origin: left top;
    }
  }

  // Is active state
  // And hover-focus interactions
  .c-link--checkbox-like {
    #{$interactions},
    &.is-active {
      text-decoration: none;

      &::before {
        background-color: theme(brand);
      }

      &::after {
        content: '';
        position: absolute;
      }
    }
  }
}
