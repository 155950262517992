@if $toc {
  /* - Order____________________Modify order of appearance of the elements */
} @else {
  /* Modify the order of appearance of the elements [object]
    ========================================================================== */

  // Object
  .o-order {
    display: flex;
    flex-direction: column;
  }

  .o-order__first {
    order: -1;
  }

  .o-order__center {
    align-self: center;
  }
}

