@if $toc {
  /* - Contextual information___Contextual information in footer & modal */
} @else {
  /* Contextual information  in footer & modal [component]
    ========================================================================== */

  .c-contextual-information {
    display: block;
    color: theme(contextual-information-txt);

    @include mq($until: S) {
      font-size: rem(14); // 14px
      line-height: lh(18, 14); // 18px
    }

    @include mq($from: S) {
      font-size: rem(12); // 12px
      line-height: lh(18, 12); // 18px
    }
  }


  .c-contextual-information {
    .right-action-modal & {
      margin-top: rem(-12); // -12px
      margin-bottom: rem(16); // 16px
    }
  }
}
