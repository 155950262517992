@if $toc {
  /* - Product table__________________Product table */
} @else {
  /* Product table [component]
    ========================================================================== */

  // Wrapper
  .c-product-table {
    // <table>
    [role='grid'] {
      border-top: 1px solid theme(default-bdc);
      width: 100%;
      font-size: rem(14); // 14px
      line-height: lh(24, 14); // 24px
      text-align: center;
    }

    // <tr>
    [role='row'] {
      display: table;
      width: 100%;
      margin-bottom: 0;
      border-bottom: 1px solid theme(default-bdc);
    }

    // Label
    .c-label {
      margin-bottom: 0;
      padding-left: 0;
      text-align: center;
    }

    // <th> & <td>
    [role='columnheader'],
    [role='gridcell'] {
      display: table-cell;
      padding-top: em(7, 14); // 7px
      padding-bottom: em(7, 14); // 7px
      vertical-align: top;
    }

    // <th>
    [role='columnheader'] {
      font-weight: 600;
    }
  }

  // Radio button
  .c-product-table .c-label {
    &::before {
      top: rem(11); // 11px
    }
    &::after {
      top: rem(15); // 11px
    }
  }

  // Diameter cell
  // [role="gridcell"].c-product-table__diameter {
  .c-product-table__diameter {
    padding-left: em(21, 14); // 21px
  }

  // Delivery cell
  [class].c-product-table__delivery {
    font-weight: 600;
  }

  // Highlighted cell
  .c-product-table__mark {
    @include mq($from: S) {
      background-color: theme(mark-bgc);
    }
  }

  // Soft reset
  .c-product-table {
    [role='gridcell'],
    span {
      color: inherit;

      &::before {
        content: normal;
      }
    }
  }

  // Cropped table
  .c-product-table.is-cropped {
    overflow: hidden;
    position: relative; // Necessary for gradient positioning
    max-height: rem(180); // 180px = 5 × 36 (1 <tr>)

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: rem(36); // 36px (1 <tr>)
      background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.3) 0%,
        #ffffff 90%
      );
    }
  }

  // Table variations
  // The table has 4 columns
  .c-product-table--4-cols {
    @include mq($until: M) {
      [role='columnheader'],
      [role='gridcell'] {
        width: 22%;
      }

      .c-product-table__delivery {
        width: 34%;
      }
    }

    @include mq($from: M) {
      [role='columnheader'],
      [role='gridcell'] {
        width: 15%;
      }

      .c-product-table__delivery {
        width: 55%;
      }
    }
  }

  // The table has 5 columns
  .c-product-table--5-cols {
    @include mq($until: M) {
      [role='columnheader'],
      [role='gridcell'] {
        width: 18%;
      }

      .c-product-table__delivery {
        width: 28%;
      }
    }

    @include mq($from: M) {
      [role='columnheader'],
      [role='gridcell'] {
        width: 13%;
      }

      .c-product-table__delivery {
        width: 48%;
      }
    }
  }
}
