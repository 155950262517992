@if $toc {
  /* - Result___________________Number of results found & items per page */
} @else {
  /* Number of results found & items per page [component]
    ========================================================================== */

  // BEM root block : .c-result
  .c-result {
    align-self: center; // Usefull when c-result is the only child

    @include mq($until: S) {
      display: block;
      text-align: center;
    }
  }

  // Number of results found
  .c-result__total {
    display: block;

    @include mq($from: S) {
      font-size: rem(14); // 14px
      line-height: lh(24, 14); // 24px
    }

    @include mq($from: M) {
      display: inline-block;
    }
  }

  // Dash separator
  .c-result__total:not(:last-child)::after {
    display: inline-block;
    width: em(7, 14); // 7px
    height: 1px;
    margin: 0 em(7, 14) 0 em(10, 14); // 0 7px 0 10px
    vertical-align: rem(4); // 4px
    background-color: theme(default-txt);

    @include mq($from: M) {
      content: '';
    }
  }
}
