@if $toc {
  /* - IE11_____________________Bugfixs for IE11 */
} @else {
  /* Bugfixs for IE11 [utility]
    ========================================================================== */

  /**
   * - The `-ms-high-contrast` CSS media feature is a Microsoft extension that describes whether the application is being displayed in high contrast mode, and with what color variation.
   * - `none` value is no longer supported in Microsoft Edge.
   *
   * @see
   * [-ms-high-contrast](https://developer.mozilla.org/fr/docs/Web/CSS/-ms-high-contrast)
   */

  @media screen and (-ms-high-contrast: none) {
    // Prevents the content (text & image) from overflowing
    [class]._width {
      width: 100%;
    }

    [class]._maxwidth {
      > h2,
      > p,
      > a,
      > img {
        max-width: 100%;
      }
    }


    // Prevents the grid from overflowing
    [class]._o-media {
      @include mq($until: S) {
        -ms-grid-columns: minmax(100%, 100%);
      }
    }


    // Shooping cart summary
    // Replace buggy display:flex behaviour
    @include mq($until: M) {
      ._c-cart-summary {
        table-layout: fixed;
      }

      ._c-cart-summary__tr {
        display: table-row;
        clear: left;
      }

      ._c-cart-summary__td {
        float: left;

        &.c-cart-summary__quantity {
          width: 75%;
        }

        &.c-cart-summary__price {
          width: 25%;
        }
      }

      .c-cart-summary--order {
        .c-cart-summary__quantity,
        .c-cart-summary__price {
          display: block;
          float: none;
          width: 100%;
        }
      }
    }
  }
}
