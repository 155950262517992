@if $toc {
  /* - Media____________________Media (image and text-like side by side) */
} @else {
  /* Media (image and text-like side by side) [component]
    ========================================================================== */

  // Media
  .c-media {
    padding-top: rem(56); // 56px
    padding-bottom: rem(64); // 64px

    @include mq($from: S) {
      padding-top: rem(64); // 64px
      padding-bottom: rem(136); // 136px
    }
  }

  // Content
  .c-media__content {
    position: relative;

    @include mq($from: S) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0 rem(24); // 24px
    }

    @include mq($from: L) {
      padding: 0 rem(64); // 64px
    }
  }

  .c-media__content::before {
    content: '';
    position: absolute;
    top: rem(-56); // -56px
    left: 0;
    width: rem(38); // 38px
    height: rem(38); // 38px
    background: url('../../../svg/icon/stars.svg')
      50%
      50% /
      #{rem(38)}
      #{rem(38)}
      no-repeat;

    @include mq($from: S) {
      top: rem(-64); // -64px
      left: rem(24); // 24px
    }

    @include mq($from: L) {
      left: rem(64); // 64px
    }
  }

  // Title
  .c-media .c-h2 {
    @include mq($from: S) {
      margin-bottom: rem(24); // 24px
    }
  }

  // Illustration
  .c-media__illustration {
    position: relative; // Usefull to ::after

    @include mq($until: S) {
      margin-top: rem(24); // 24px
    }
  }

  // Decorative round
  .c-media__illustration::after {
    content: '';
    position: absolute;
    right: rem(-28); // -28px
    bottom: rem(-32); // -32px
    z-index: -1;
    width: rem(76); // 76px
    height: rem(76); // 76px
    background-color: theme(media__illustration__after-bgc);
    border-radius: 50%;

    @include mq($from: S) {
      right: rem(-72); // -72px
      bottom: rem(-84); // -84px
      width: rem(232); // 232px
      height: rem(232); // 232px
    }
  }

  // Specific case
  // Media reverse
  .o-media--reverse {
    @include mq($until: S) {
      &.c-media {
        padding-top: 0;
        padding-bottom: rem(40); // 40px
      }

      .c-media__content {
        margin-top: rem(112); // 112px
      }

      .c-media__illustration {
        margin-top: 0;
      }

      .c-media__illustration::after {
        right: auto;
        left: rem(-28); // -28px
      }
    }

    @include mq($from: S) {
      .c-media__illustration::after {
        left: rem(-72); // -72px
      }
    }
  }

  // Remove decorative stars
  // on media title & media with product list
  .c-media--product,
  .c-media--title {
    .c-media__content::before {
      content: normal;
    }
  }

  // Specific case
  // Media with product list
  .c-media--product {
    padding-bottom: 0;

    .c-media__content {
      padding-right: 0;
      padding-left: 0;
    }

    .o-media__illustration {
      @include mq($from: S) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .c-media__illustration {
      @include mq($until: S) {
        max-width: rem(464); // 464px
        margin: rem(40) auto 0; // 40px auto 0 auto
      }
    }

    // Decorative round
    .c-media__illustration::after {
      top: rem(30); // 30px
      right: rem(-38); // -38px
      bottom: auto;
      left: auto;
      width: rem(76); // 76px
      height: rem(76); // 76px

      @include mq($from: S) {
        top: rem(-60); // -60px
        right: rem(-60); // -60px
        width: rem(120); // 120px
        height: rem(120); // 120px
      }
    }
  }

  // Specific case
  // Media reverse with product list
  .o-media--reverse.c-media--product {
    padding-top: 0;
    padding-bottom: 0;

    .c-media__illustration {
      @include mq($until: S) {
        margin-top: 0;
      }
    }

    .c-media__content {
      @include mq($until: S) {
        margin-top: rem(16); // 16px
      }
    }

    // Decorative round
    .c-media__illustration::after {
      right: auto;
      left: rem(-38); // -38px

      @include mq($from: S) {
        left: rem(-60); // -60px
      }
    }
  }

  // Specific case
  // Media title
  .c-media--title {
    @include mq($from: S) {
      padding-top: rem(72); // 72px
      padding-bottom: rem(104); // 104px
    }

    .c-media__illustration::after {
      /* stylelint-disable-line */
      bottom: rem(-24); // -24px

      @include mq($from: S) {
        right: rem(-112); // -112px
        bottom: rem(-80); // -80px
      }
    }
  }

  .c-media__title {
    @include font($type: bold, $family: $serif);
    @extend %c-h1;
    position: relative; // Usefull to ::before
    margin-bottom: 0;
    color: theme(media__title-txt);
  }

  .c-media__title::before {
    content: '';
    position: absolute;
    top: rem(-46); // -46px
    left: 0;
    width: rem(38); // 38px
    height: rem(38); // 38px
    background: url('../../../svg/icon/stars.svg')
      50%
      50% /
      #{rem(38)}
      #{rem(38)}
      no-repeat;

    @include mq($from: S) {
      top: rem(-64); // -64px
    }
  }
}
