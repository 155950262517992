@if $toc {
  /* - Heading__________________Heading (h1 -> h6) */
} @else {
  /* Simple default styles for headings 1 through 6 [element]
   ========================================================================== */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div[role='heading'] {
    @include font($type: bold, $family: $serif);
    margin: 0;
    color: theme(heading-txt);
  }

  h1,
  h2,
  div[role='heading'][aria-level='1'],
  div[role='heading'][aria-level='2'] {
    margin-bottom: rem(16); // 16px
  }

  h3,
  h4,
  h5,
  h6 {
    margin-bottom: rem(8); // 8px
  }

  h1,
  div[role='heading'][aria-level='1'] {
    font-size: rem(34); // 34px
    line-height: lh(44, 34); // 44px

    @include mq($from: S) {
      font-size: rem(52); // 52px
      line-height: lh(64, 52); // 64px
      letter-spacing: 1.5px;
    }
  }

  h2,
  div[role='heading'][aria-level='2'] {
    font-size: rem(24); // 24px
    line-height: lh(34, 24); // 34px

    @include mq($from: S) {
      font-size: rem(34); // 34px
      line-height: lh(46, 34); // 46px
      letter-spacing: 1px;
    }
  }

  h3 {
    font-size: rem(20); // 20px
    line-height: lh(28, 20); // 28px

    @include mq($from: S) {
      font-size: rem(24); // 24px
      line-height: lh(32, 24); // 32px
    }
  }

  h4 {
    font-size: rem(16); // 16px
    line-height: lh(24, 16); // 24px

    @include mq($from: S) {
      font-size: rem(18); // 18px
      line-height: lh(24, 18); // 24px
    }
  }

  h5,
  h6 {
    font-size: 1rem; // 16px
    line-height: 1.5; // 24px
  }
}
