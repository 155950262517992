@if $toc {
  /* - Flash sale__________________Flash sales countdown */
} @else {
  /* Flash sales countdown [component]
    ========================================================================== */

  .c-flash-sale {
    margin-bottom: rem(16); // 16px
    padding: rem(8) rem(8) rem(12); // 8px 8px 12px 8px
    color: theme(flash-sale-txt);
    background-color: theme(flash-sale-bgc);

    @include mq($from: S) {
      margin-bottom: rem(24); // 24px
      padding: rem(12) rem(18); // 12px 18px
    }
  }


  // "End in" string
  .c-flash-sale__ending {
    display: block;

    @include mq($from: S) {
      font-size: rem(14); // 14px
      line-height: 1; // 14px
    }
  }


  // Countdown
  .c-flash-sale__countdown {
    @include font($type: bold, $family: $serif);
    @extend %c-h3;
    display: inline-block;
    margin-right: rem(16); // 16px
  }


  // Link
  .c-flash-sale__link {
    @include mq($from: S) {
      max-width: rem(184);
    }
  }
}
