@if $toc {
  /* - color____________________Color */
} @else {
  /* Color [utility]
    ========================================================================== */

  .u-color-brand {
    color: theme(brand);
  }
}
