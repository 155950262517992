@include mq($until: S) {
  // Add gift wrapping (or any first level option of checkbox type).
  // Sticky positioning is only for product page.
  // See component button and sticky add to cart for positioning.
  .c-product-information .c-option__checkbox {
    position: fixed;
    right: 0;
    z-index: 666;
    height: 48px;
    border-top: 1px solid $alto;
    bottom: 156px; // 156px : 108px + 48px
    width: 100%;
    padding: 1rem;
    background-color: theme(btn--sticky-engraving-bgc);
    white-space: nowrap;
    // Adjust size to container.
    // "calc" is used as a hack since Sass won't allow mixed units
    // raising an error "Incompatible units".
    max-width: 100%;
    font-size: #{'min(4vw, 16px)'};
  }
}
