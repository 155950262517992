@if $toc {
  /* - Modal____________________Modal */
} @else {
  /* Modal [component]
    ========================================================================== */

  /**
  * @see  [Accessible modal window using ARIA](https://van11y.net/accessible-modal/)
  */

  // Removes scroll when modal is opened
  .no-scroll {
    overflow: hidden;
  }

  // Overlay covers everything
  [class$='modal-overlay'] {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 668;
    background-color: rgba(theme(modal-overlay-bgc), 0.5);
  }

  .invisible {
    @include visually-hidden($extend: true, $important: false);
  }

  // Modal
  [class$='modal']:not(.js-modal) {
    position: fixed;
    z-index: 667;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  .alma-eligibility-modal-desktop-modal {
    position: relative !important;
  }

  // Close button
  [class$='modal-close']:not(.c-btn) {
    @include unstyled-button($extend: true, $important: false);
    position: absolute;
    top: rem(16); // 16px
    right: rem(16); // 16px
    padding: 0 rem(28) 0 rem(8); // 24px 8px
    font-size: rem(14); // 14px
    line-height: lh(24, 14); // 24px
    background: url('../../../svg/icon/cross.svg')
      right
      #{rem(4)}
      top
      50% /
      auto
      #{rem(14)}
      no-repeat;

    @include mq($from: S) {
      font-size: rem(12); // 12px
      line-height: lh(24, 12); // 24px
    }
  }

  // Modal wrapper
  [class$='modal__wrapper'] {
    position: relative;
    height: inherit;
    background: theme(default-bgc);
  }

  // Centered modal
  [class].center-modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center center;
    animation: popCenter var(--animation-duration);

    @include mq($from: S) {
      width: 95%;
      // height: auto;
      // Force height and allow vertical scrolling on modal
      max-height: 90vh;

      .center-modal__wrapper {
        overflow-y: auto;
      }
    }

    @include mq($from: L) {
      width: 75%;
    }
  }

  .center-modal__wrapper {
    padding: rem(48) rem(16) rem(16); // 48px 16px 16px 16px

    @include mq($from: S) {
      max-width: rem(952); // 952px
      margin-right: auto;
      margin-left: auto;
      padding: rem(56) rem(98) rem(48); // 56px 98px 48px
    }

    @include mq($from: M) {
      padding-right: rem(122); // 122px
      padding-left: rem(122); // 122px
    }
  }

  .center-modal-title {
    @extend %c-h3;
    margin-bottom: rem(16); // 16px
    text-align: center;

    @include mq($from: S) {
      margin-bottom: rem(24); // 24px
    }
  }

  // Top modal
  [class].top-modal {
    top: 0;
    left: 0;
    transform: translateY(0);
    animation: slideBottom var(--animation-duration);

    @include mq($from: S) {
      height: auto;
    }
  }

  .top-modal__wrapper {
    padding: rem(48) rem(16) rem(16); // 48px 16px 16px 16px

    @include mq($from: S) {
      padding: rem(56) rem(98) rem(48); // 56px 98px 48px
    }

    @include mq($from: M) {
      padding-right: rem(122); // 122px
      padding-left: rem(122); // 122px
    }
  }

  .top-modal-title {
    @extend %c-h3;
    margin-bottom: rem(16); // 16px
    text-align: center;

    @include mq($from: S) {
      margin-bottom: rem(24); // 24px
    }
  }

  // Right modal
  // Right modal with action buttons
  [class].right-modal,
  [class].right-action-modal {
    top: 0;
    right: 0;
    left: auto;
    transform: translateX(0);
    animation: slideLeft var(--animation-duration);

    @include mq($from: S) {
      width: rem(464); // 464px
    }
  }

  .right-modal__wrapper,
  .right-action-modal__wrapper {
    padding-top: rem(48); // 48px
  }

  .right-modal__content,
  .right-action-modal__content {
    max-height: 100%;
    overflow-y: auto;

    // Disable custom page editorial margin/style
    .s-cms {
      margin: 0;
    }

    .c-contextual-information {
      @include mq($until: S) {
        margin-right: rem(20); // 20px: minimum scrollbar space
        margin-left: rem(20); // 20px: minimum scrollbar space
      }

      @include mq($from: S) {
        padding-right: rem(32); // 32px
        padding-left: rem(32); // 32px
      }
    }
  }

  .right-modal-title,
  .right-action-modal-title {
    @extend %c-h4;
    text-align: center;
  }

  // Right modal
  .right-modal__wrapper {
    padding-bottom: rem(16); // 16px
  }

  .right-modal__content {
    padding-right: rem(20); // 20px: minimum scrollbar space
    padding-left: rem(20); // 20px: minimum scrollbar space

    @include mq($from: S) {
      padding-right: rem(32); // 32px
      padding-left: rem(32); // 32px
    }

    // Force styling for dynamic contents
    // This has been duplicated from `c-h5` rules.
    h2 {
      margin-bottom: rem(8); // 8px
      @include font($type: semibold, $family: $defaut-font-family);
      font-size: rem(16); // 16px
      line-height: lh(24, 16); // 24px

      @include mq($from: S) {
        font-size: rem(14); // 14px
        line-height: lh(20, 14); // 20px
      }
    }

    // This has been duplicated from `u-mb-2` rules.
    p {
      margin-bottom: rem(16);
    }
  }

  // Right modal with action buttons
  .right-action-modal__content {
    max-height: calc(100% - #{rem(80)}); // 100% - action button area
  }

  // Fieldset separator
  .right-action-modal__separator {
    &:last-of-type {
      padding-bottom: rem(16);
    }

    @include mq($until: S) {
      margin-right: rem(20); // 20px: minimum scrollbar space
      margin-left: rem(20); // 20px: minimum scrollbar space
    }

    @include mq($from: S) {
      padding-right: rem(32); // 32px
      padding-left: rem(32); // 32px
    }

    // With the exception of the penultimate element
    &:not(:nth-last-child(-n + 2)) {
      /* stylelint-disable-line */
      margin-bottom: rem(16); // 16px
      padding-bottom: rem(32); // 32px
      border-bottom: 1px solid theme(default-bdc);
    }

    legend {
      margin-bottom: rem(16); // 16px
      font-weight: 600;
      text-align: center;
    }

    textarea {
      min-height: rem(132); // 132px = 5 lines + padding & border
    }

    select {
      margin-bottom: rem(16);
    }
  }

  // Validation and cancellation button
  .right-action-modal__btn {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: rem(12); // 12px
    padding-bottom: rem(20); // 20px
    text-align: center;
    background: theme(default-bgc);
    border-top: 1px solid theme(default-bdc);

    @include mq($from: S) {
      right: 0;
      left: 0;
    }
  }

  // Animations
  @keyframes popCenter {
    0% {
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
    }

    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }

  @keyframes slideBottom {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slideLeft {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
}
