@if $toc {
  /* - Disclosure_______________Disclosure trigger */
} @else {
  /* Disclosure trigger [component]
    ========================================================================== */

  // Common rules
  .c-disclosure {
    @include unstyled-button($extend: true, $important: false);
    position: relative;
    width: 100%;
    color: theme(default-txt);
    text-align: left;

    #{$interactions} {
      text-decoration: underline;
      outline: 0;
    }

    &[aria-expanded]::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: rem(32); // 32px
      background: url('../../../svg/icon/arrow.svg')
        50% /
        auto
        #{rem(11)}
        no-repeat;
      transform: rotate(90deg);
      transition: transform var(--transition-duration);
    }

    &[aria-expanded='true']::after {
      transform: rotate(-90deg);
    }
  }

  // Footer disclosure
  .c-disclosure--footer {
    padding: rem($grid-gutter--us); // 16px
    font-weight: 600;
    letter-spacing: inherit;
    text-transform: uppercase;

    &[aria-expanded]::after {
      right: rem(16); // 16px
    }

    @include mq($from: S) {
      padding-right: rem($grid-gutter); // 24px
      padding-left: rem($grid-gutter); // 24px
    }
  }

  // FAQ disclosure
  .c-disclosure--faq {
    padding: rem(11) rem(32) rem(11) rem(16); // 11px 32px 11px 16px
    font-weight: 600;

    @include mq($from: S) {
      padding: rem(22) rem(48) rem(22) rem(32); // 22px 48px 22px 32px
    }
  }

  .c-disclosure--faq[aria-expanded]::after {
    @include mq($from: S) {
      right: rem(16); // 16px
    }
  }

  .c-disclosure--faq[aria-expanded='false'] {
    border-bottom: 1px solid theme(default-bdc);
  }

  // Filter disclosure
  .c-disclosure--filter {
    padding: rem(10) 0; // 10px 0

    &[aria-expanded]::after {
      right: rem(-8); // -8px
    }
  }

  // Notice disclosure
  .c-disclosure--notice {
    @include font($type: semibold, $family: $defaut-font-family);
    color: theme(brand);
    text-transform: uppercase;
  }

  @include mq($until: S) {
    .c-disclosure--notice {
      padding: rem(16); // 16px
      border-top: 1px solid theme(default-bdc);

      &[aria-expanded]::after {
        right: rem(8); // 8px
      }
    }
  }

  @include mq($from: S) {
    .c-disclosure--notice {
      color: theme(tablist__link-txt);
      font-size: rem(14); // 14px
      line-height: lh(21, 14); // 21px

      &[aria-expanded='false']::after {
        content: normal;
      }

      // Current page
      &[aria-expanded='true'] {
        color: theme(tablist__link-txt_current);
      }
    }
  }

  // Voucher disclosure
  .c-disclosure--voucher {
    /* stylelint-disable-line */
    margin-top: rem(8); // 8px
    padding: rem(16) rem(48) rem(16) rem(16); // 16px 48px 16px 16px
    font-weight: 600;
    background-color: theme(default-bgc);

    @include mq($from: S) {
      font-size: rem(14); // 14px
      line-height: lh(21, 14); // 21px
    }

    &[aria-expanded]::after {
      /* stylelint-disable-line */
      right: rem(8); // 8px
    }
  }

  // Basket summary disclosure
  .c-disclosure--basket-summary {
    display: flex;
    justify-content: space-between;
    padding: rem(16); // 16px
    color: theme(disclosure--basket-summary-txt);
    font-weight: 600;
    font-size: rem(14); // 14px
    line-height: lh(24, 14); // 24px
    letter-spacing: inherit;
    background-color: theme(default-bgc);

    @include mq($from: S) {
      display: none;
    }
  }

  .c-disclosure--basket-summary {
    &[class]::after {
      content: normal;
    }
  }

  .c-basket-summary__label {
    [aria-expanded] > &::after {
      content: '';
      display: inline-block;
      width: rem(24); // 24px
      height: rem(24); // 24px
      margin-left: rem(8); // 8px
      vertical-align: bottom;
      background: url('../../../svg/icon/arrow.svg')
        50% /
        auto
        #{rem(11)}
        no-repeat;
      transform: rotate(90deg);
      transition: transform var(--transition-duration);
    }

    [aria-expanded='true'] > &::after {
      transform: rotate(-90deg);
    }
  }

  // Opening Hour disclosure
  .c-disclosure--opening-hour {
    width: auto;
    padding-right: rem(32); // 32px
    color: theme(brand);
    font-weight: 600;

    @include mq($from: S) {
      font-size: rem(14); // 14px
      line-height: lh(21, 14); // 21px
    }
  }

  // Cart total disclosure
  .c-disclosure--cart {
    min-width: rem(124); // 124px
    padding-right: rem(32); // 32px
    color: theme(brand);
  }
}
