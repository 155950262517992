@if $toc {
  /* - Added to cart____________Product added to cart */
} @else {
  /* Product added to cart [component]
    ========================================================================== */

  // Product : name + preview
  .c-added-to-cart__product {
    padding: rem(16) 0; // 16px 0
    border-top: 1px solid theme(default-bdc);
    border-bottom: 1px solid theme(default-bdc);

    @include mq($from: S) {
      padding: rem(24) 0; // 24px 0
    }
  }

  // Product details
  .c-added-to-cart__item {
    padding-left: rem(16); // 16px
    vertical-align: middle;

    @include mq($from: S) {
      padding-left: rem(24); // 24px
    }
  }

  // Product name
  .c-added-to-cart__name {
    @include font($type: bold, $family: $serif);
    @extend %c-h4;
    display: block;
    margin-bottom: rem(8); // 8px

    @include mq($from: S) {
      margin-bottom: rem(24); // 24px
    }
  }

  // Thumbnail
  .c-added-to-cart__thumbnail {
    width: rem(96); // 96px
    vertical-align: middle;

    @include mq($from: S) {
      width: rem(160); // 160px
    }
  }

  // Preview
  .c-added-to-cart__preview {
    padding: rem(4) rem(8); // 4px 8px
    border: 1px solid theme(default-bdc);

    @include mq($from: S) {
      padding: rem(16) rem(24); // 16px 24px
    }
  }

  // Gift wrap option / go back / view cart
  .c-added-to-cart__action {
    margin-bottom: rem(16); // 16px
    text-align: center;

    @include mq($from: S) {
      margin-bottom: rem(24); // 24px
    }
  }

  .c-added-to-cart__gift {
    // max-width: rem(264); // 264px
    margin: rem(16) auto 0; // 16px auto 0 auto
    padding: rem(8); // 8px
    background-color: theme(added-to-cart__gift-bgc);

    @include mq($from: S) {
      // max-width: rem(240); // 240px
    }
  }
}
