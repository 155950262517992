@if $toc {
  /* - Form_____________________Forms in shopping tunnel (delivery / shipping) */
} @else {
  /* Forms in shopping tunnel (delivery / shipping) [component]
    ========================================================================== */

  // Form
  .c-form {
    background-color: theme(default-bgc);
  }

  // Legend
  .c-form__legend {
    text-align: center;
    background-color: inherit;
  }

  // Visual separation between blocks
  .c-form__block {
    position: relative; // Usefull to .c-form__fieldset::after
  }

  .c-form__block:not(:last-child) {
    margin-bottom: rem(16); // 16px

    @include mq($from: S) {
      margin-bottom: rem(8); // 8px
    }
  }

  .c-form__block::after {
    content: '';
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    height: rem(16); // 16px
    background-color: theme(form--delivery__fieldset-bdc);

    @include mq($from: S) {
      height: rem(8); // 8px
    }
  }

  // Radio button blocks
  .c-form__block--radio {
    padding: rem(16); // 16px

    @include mq($from: S) {
      padding: rem(24); // 24px
    }
  }

  // Radio button label
  [class].c-form__label {
    position: relative; // Usefull to .c-form__price & payment
    margin-bottom: 0;
    font-size: rem(16); // 16px
    line-height: lh(24, 16); // 24px

    @include mq($until: S) {
      padding-right: rem(88); // 88px
    }
  }

  // Credit card label
  [class].c-form__label--credit-card {
    @include mq($until: S) {
      min-height: rem(72); // 72px = .c-form__payment's height
      padding-right: rem(144); // 144px
    }
  }

  // Price & payment logo (in label)
  .c-form__price,
  .c-form__payment {
    @include font($type: bold, $family: $serif);
    position: absolute;
    top: 0;
    right: 0;
    font-size: rem(18); // 18px
    line-height: lh(24, 18); // 24px
  }

  // Wrapper of payment logo (in label)
  .c-form__payment {
    top: rem(-6); // -6px

    @include mq($until: S) {
      max-width: rem(160); // 160px
      text-align: right;
    }
  }

  // Payment logo
  .c-form__payment-logo {
    display: inline-block;
    margin-bottom: rem(12); // 12px

    &:not(:first-child) {
      margin-left: rem(16); // 16px
    }
  }

  .c-form__payment-info {
    font-style: italic;
    font-size: 0.8rem;
    max-width: 350px;
  }

  // Details in radio button blocks
  // Payment method form, date of delivery
  .c-form__details {
    @include mq($from: M) {
      margin-left: rem(28); // 28px
    }

    // Hide element as long as the button is not selected
    input:not(:checked) ~ & {
      /* stylelint-disable-line */
      display: none;
    }
  }

  .c-form__details--space {
    margin-top: rem(32); // 32px
  }

  // Go to button wrapper
  // Pay button wrapper
  [class].c-form__next-step,
  .c-form__pay {
    text-align: center;

    @include mq($until: S) {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 666;
      margin: 0;
      padding: rem(8) rem(16); // 8px 16px
      background-color: theme(default-bgc);
    }
  }

  [class].c-form__next-step {
    @include mq($from: S) {
      padding: rem(32) 0; // 32px 0
    }
  }

  .c-form__pay {
    @include mq($from: S) {
      width: 100%;
      padding-top: rem(16); // 16px
    }
  }

  // Go to button
  // Pay button
  .c-form__next-step,
  .c-form__pay {
    .c-btn {
      @include mq($until: S) {
        width: calc(100% - #{rem(32)}); // 100% - 32px
      }
    }
  }

  // Specific case
  // Delivery form
  .c-form__block--fieldset {
    padding: 0 rem(16) rem(16); // 0 16px 16px 16px

    @include mq($from: S) {
      margin-bottom: rem(8); // 8px
      padding: 0 0 rem(32); // 0 0 32px 0
    }
  }

  // Legend
  .c-form__legend {
    padding: rem(16) 0; // 16px 0 16px
    color: theme(form--delivery__legend-txt);
    font-weight: 600;

    @include mq($from: S) {
      padding-top: rem(32); // 32px
    }
  }

  // Same as delivery checkbox
  .c-form__same-as {
    text-align: center;

    .c-label {
      display: inline-block;
    }

    // The form is displayed
    [aria-expanded='true'] + .c-label {
      margin-bottom: rem(8); // 8px

      @include mq($from: S) {
        margin-bottom: rem(16); // 16px
      }
    }
  }

  // Form wrapper
  .c-form {
    [hidden] {
      display: none;
    }

    .u-max-wd-6 {
      @include mq($from: S, $until: M) {
        padding-right: rem(16); // 16px
        padding-left: rem(16); // 16px
      }
    }
  }

  // Invoice details
  [class].c-form__invoice {
    width: 100%;
    margin-top: rem(16); // 16px
    font-size: rem(14); // 14px
    line-height: lh(21, 14); // 21px
  }

  // Personalized message
  .c-form__add-message {
    margin-bottom: rem(16); // 16px
    font-weight: 600;

    @include mq($from: S) {
      margin-bottom: rem(8); // 8px
      font-size: rem(14); // 14px
      line-height: lh(24, 14); // 24px
    }
  }

  // Number of characters/lines still to be entered
  .c-form__textarea-countdown {
    @include mq($until: S) {
      display: block;

      &::before {
        content: normal;
      }
    }
  }

  // Specific case
  // Shipping form

  // Date of delivery (in label)
  .c-form__delivery-date {
    display: block;
    font-weight: normal;
    font-size: rem(12); // 12px
    line-height: lh(18, 12); // 18px
  }

  // Custom info message (multiple carriers)
  .c-form__block--info {
    padding: rem(16); // 16px

    @include mq($from: S) {
      padding: rem(24) rem(32) rem(24) rem(24); // 24px 32px 24px 24px
    }

    p:not(:first-child) {
      padding-top: rem(16);
    }
  }

  // Date of event / Comment
  .c-form__block--comment {
    padding: rem(16); // 16px

    @include mq($from: S) {
      padding: rem(24) rem(32) rem(24) rem(24); // 24px 32px 24px 24px
    }

    @include mq($from: M) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  // Event details
  .c-form__event-content {
    font-size: rem(14); // 14px
    line-height: lh(18, 14); // 21px

    @include mq($from: M) {
      min-height: rem(64); // Background height
      margin-right: rem($grid-gutter); // 24px
      padding-left: rem(72); // 68px
      background: url('../../../../svg/icon/event.svg') 0 50% / #{rem(64)} #{rem(
          64
        )} no-repeat;
    }
  }

  // Event title
  .c-form__event-title {
    @include font($type: bold, $family: $serif);
    @extend %c-h4;
    display: block;
    margin: rem(8) 0 rem(4); // 8px 0 4px
    color: theme(brand);
  }

  // Event button
  .c-form__event-btn {
    margin-top: rem(16); // 16px
    text-align: center;

    @include mq($from: M) {
      flex-shrink: 0;
      margin-top: 0;
    }
  }

  // Specific case
  // Payment form

  // Card number
  [class].c-form__card-number {
    @include mq($until: S) {
      width: 100%;
    }
  }
}
