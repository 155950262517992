@if $toc {
  /* - Helper___________________Icon attached to form fields */
} @else {
  /* Icon attached to form fields [component]
    ========================================================================== */

  .c-helper {
    position: absolute;
    top: rem(36); // 36px
    right: rem(8); // 8px
    width: rem(32); // 32px
    height: rem(32); // 32px
    margin: 0;
    background-color: theme(default-bgc);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: #{rem(32)} #{rem(32)};
  }

  // Password
  .c-helper--password {
    background-image: url('../../../svg/icon/pass-hidden.svg');

    input:checked + & {
      background-image: url('../../../svg/icon/pass-shown.svg');
    }
  }

  // Calendar
  .c-helper--calendar {
    @include unstyled-button($extend: true, $important: false);
    top: rem(7); // 7px
    background-image: url('../../../svg/icon/calendar.svg');
  }

  // Search
  .c-helper--search {
    @include unstyled-button($extend: true, $important: false);
    top: rem(8); // 8px
    background-image: url('../../../svg/icon/search.svg');
  }
}
