@if $toc {
  /* - Message view_____________Message viewing */
} @else {
  /* Message viewing [component]
    ========================================================================== */

  // Root element
  .c-message-view {
    margin-bottom: rem(80); // 80px

    @include mq($from: S) {
      margin-bottom: rem(112); // 112px
    }
  }


  // Container
  .c-message-view__container {
    margin: rem(8) 0 rem(16); // 8px 0 16px 0
    padding-top: rem(16); // 16px
    border-top: 1px solid theme(default-bdc);
    border-bottom: 1px solid theme(default-bdc);

    @include mq($from: S) {
      margin: rem(24) 0; // 24px 0
      $padding: $grid-col + $grid-gutter;  /* stylelint-disable-line */
      padding: rem(40) rem($padding) rem(24); // 40px 122px 24px 122px
    }
  }


  // Title
  .c-message-view__title {
    @include font($type: regular, $family: $sans-serif);
    font-size: rem(16); // 16px
    line-height: lh(21, 16); // 21px

    @include mq($from: S) {
      line-height: lh(24, 16); // 24px
    }
  }


  // Message subject
  .c-message-view__subject {
    display: block;
    font-weight: 600;
  }


  // Messages from me and the site
  .c-message-view__me,
  .c-message-view__premiercadeau {
    line-height: lh(21, 16); // 21px

    @include mq($from: S) {
      font-size: rem(14); // 14px
      line-height: lh(24, 14); // 21px
    }
  }

  // My message
  .c-message-view__me {
    margin-left: rem(32); // 32px

    @include mq($from: S) {
      margin-left: rem(40); // 40px
    }
  }

  // Site message
  .c-message-view__premiercadeau {
    margin-right: rem(32); // 32px

    @include mq($from: S) {
      margin-right: rem(40); // 40px
    }
  }


  // Message content
  .c-message-view__content {
    display: block;
    padding: rem(16); // 16px
  }

  // My message
  .c-message-view__me .c-message-view__content {
    background-color: theme(message-view__me-bgc);
  }

  // Site message
  .c-message-view__premiercadeau .c-message-view__content {
    border: 1px solid theme(default-bdc);
  }


  // Date and time of the message
  .c-message-view__timestamp {
    display: block;
    margin: rem(8) 0 rem(16); // 8px 0 16px 0
    color: theme(message-view__timestamp-txt);

    .c-message-view__me & {
      text-align: right;
    }
  }


  // Submit button
  .c-message-view__action {
    display: block;
    text-align: right;
  }
}

