@if $toc {
  /* - Contact__________________Contact insert in header, footer  & FAQ */
} @else {
  /* Contact insert [component]
    ========================================================================== */

  // Root element
  .c-contact {
    padding: rem(16); // 16px
    border: 1px solid theme(default-bdc);
  }

  a.c-contact {
    /* stylelint-disable-line */
    #{$interactions} {
      text-decoration: none;
      border-color: theme(brand);
    }
  }

  // Header variation
  .c-header__contact {
    display: block;
    order: -1;
    padding: rem(12) rem(16); // 12px 16px

    @include mq($from: S) {
      padding: rem(8) rem(48) rem(8) rem(16); // 8px 48px 8px 16px
      background: url('../../../../svg/icon/contact.svg') right #{rem(8)} top 50% /
        #{rem(47)} #{rem(47)} no-repeat;
    }
  }

  // Catchphrase
  .c-contact__teaser {
    display: inline-block;
    margin-bottom: rem(8); // 8px
    color: theme(default-txt);
    font-weight: 600;

    @include mq($from: S) {
      font-size: rem(14); // 14px
    }

    // Header variation
    .c-header__contact & {
      /* stylelint-disable-line */
      // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
      @media (hover: none),
        (hover: hover) and (max-width: 49.99em),
        (-ms-high-contrast: none) and (max-width: 49.99em) {
        display: none;
      }

      @include mq($from: S) {
        margin-bottom: 0;
        font-size: rem(12); // 12px
        line-height: lh(12, 12); // 12px
      }
    }
  }

  // Phone number
  .c-contact__phone {
    @include font($type: bold, $family: $serif);
    display: block;
    margin-bottom: rem(4); // 4px
    color: theme(brand);
    font-size: rem(20); // 20px
    line-height: lh(24, 20); // 24px

    @include mq($from: S) {
      font-size: rem(18); // 18px
      line-height: lh(24, 18); // 24px
    }

    // Header variation
    .c-header__contact & {
      /* stylelint-disable-line */
      @include mq($from: S) {
        margin-bottom: 0;
        line-height: 1;
      }
    }
  }

  // Opening hours
  .c-header__contact .c-contact__hour {
    // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
    @media (hover: hover) and (min-width: 50em),
      (-ms-high-contrast: none) and (min-width: 50em) {
      display: none;
    }
  }

  // Mail
  .c-contact__mail {
    display: block;
    margin-top: rem(8); // 8px

    // Header variation
    .c-header__contact & {
      // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
      @media (hover: hover) and (min-width: 50em),
        (-ms-high-contrast: none) and (min-width: 50em) {
        display: none;
      }
    }
  }
}
