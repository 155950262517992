@if $toc {
  /* - Table____________________Mimic the behaviour of a table */
} @else {
  /* Mimic the behaviour of a table [object]
    ========================================================================== */

  // Table
  .o-table {
    display: table;
    width: 100%;
  }

  .o-table--reverse {
    direction: rtl;
  }

  // Cell
  .o-table__cell {
    display: table-cell;
    vertical-align: top;

    .o-table--half & {
      width: 50%;
    }

    .o-table--reverse & {
      direction: ltr;
    }
  }
}
