/* Width [mixin]
  ========================================================================== */

/**
 * Applies calculated width in percent
 * Prerequisite: all items have the same width
 * @include equal-width($item, $gutter-width);
 *
 * | Parameter      | Type    | Value | Description       |
 * |----------------|---------|-------|-------------------|
 * |`$item`         | number  | none  | Number of items   |
 * |`$gutter-width` | number  | 24    | Gutter width      |
 *
 *  .usage {
 *    @include equal-width($item: 3, $gutter-width: 0);
 *  }
 */

// Equal width mixin
@mixin equal-width($items, $gutter-width: $grid-gutter) {
  $gutter: $items - 1;
  $gutter-total: $gutter-width * $gutter;

  @if $gutter-width == 0 {
    // Calculation => [1] / [2]
    // [1] : full width
    // [2] : number of items
    width: calc(100% / #{$items});
  } @else {
    // Calculation => ([1] - [2] * [3]) /[4]
    // [1] : full width
    // [2] : gutter width
    // [3] : number of gutters
    // [4] : number of items
    width: calc((100% - #{rem($gutter-total)}) / #{$items});
  }
}

/**
 * Applies calculated width in percent
 * @include ratio-width($item, $total-item);
 *
 * | Parameter      | Type    | Value        | Description           |
 * |----------------|---------|--------------|-----------------------|
 * |`$item`         | number  | none         | Number of items       |
 * |`$total-item`   | number  | none         | Total number of items |
 * |`$gutter-width` | number  | $grid-gutter | Total number of items |
 *
 *  .usage {
 *    @include ratio-width($item: 4, $total-item: 10, $grid-gutter: 0)
 *  }
 */

// Percentage width mixin
@mixin ratio-width($item, $total-item, $gutter-width: $grid-gutter) {
  $gutter: $item - 1;
  $total-gutter: $total-item - 1;

  $item-width: $item * $grid-col + $gutter * $gutter-width;
  $total-width: $total-item * $grid-col + $total-gutter * $gutter-width;

  $ratio: calc($item-width / $total-width);
  $width: $ratio * 100%;
  $gutter: calc($gutter-width / 2);

  width: calc(#{$width} - #{rem($gutter)});
}

/**
 * Applies calculated width in rem
 * @include fixed-width($item, $total-item);
 *
 * | Parameter      | Type    | Value | Description           |
 * |----------------|---------|-------|-----------------------|
 * |`$item`         | number  | none  | Number of items       |
 *
 *  .usage {
 *    @include fixed-width($item: 4)
 *  }
 */

// Fixed width mixin
@mixin fixed-width($item) {
  $gutter: $item - 1;
  $width: $grid-col * $item + $grid-gutter * $gutter;

  width: rem($width);
}
