@if $toc {
  /* - Widget images_________________Widget images */
} @else {
  /* Widget images [component]
    ========================================================================== */

  /*
  IE will not automatically size (or hide) widget images because it doesn't
  support `srcset` and, more importantly, `sizes` attributes.  So we need these
  to enforce sizing properly.  This has to be kept in sync with `images.yml` /
  `widgets.yml`.  These classes are added by the `widget_picture_tag` helper.
  */

  // Banners

  .c-widget-image--banner-image-header-large,
  .c-widget-image--banner-image-header-medium {
    width: rem(1440); // Desktop
    @include mq($until: S) {
      width: rem(768); // Mobile
    }
  }
  .c-widget-image--banner-image-header-title-left,
  .c-widget-image--banner-image-header-title-right,
  .c-widget-image--banner-image-header-text-left,
  .c-widget-image--banner-image-header-text-right {
    width: rem(708); // Desktop
    @include mq($until: S) {
      width: rem(768); // Mobile
    }
  }

  // Selection groups

  .c-widget-image--selection-group-image-flat-products-left,
  .c-widget-image--selection-group-image-flat-products-right {
    width: rem(464); // Desktop
    @include mq($until: S) {
      display: none; // Mobile (desktop-only)
    }
  }
  .c-widget-image--selection-group-image-text-products-carousel-right {
    width: rem(708); // Desktop
    @include mq($until: S) {
      display: none; // Mobile (desktop-only)
    }
  }

  // Slides

  .c-widget-image--slide-image-edito {
    width: rem(464); // Desktop
    @include mq($until: S) {
      width: rem(768); // Mobile
    }
  }
  .c-widget-image--slide-image-left-text-tabs {
    width: rem(708); // Desktop
    @include mq($until: S) {
      width: rem(768); // Mobile
    }
  }
  .c-widget-image--slide-image-vertical {
    width: rem(464);
  }
}
