@if $toc {
  /* - List-inline______________Displays a list of items in one line */
} @else {
  /* List-inline [object]
    ========================================================================== */

  .o-list-inline {
    margin-left: 0;
    list-style: none;
  }

  .o-list-inline__item {
    display: inline-block;
  }

  @include mq($from: S) {
    .o-list-inline--spaced .o-list-inline__item:not(:first-child) {
      margin-left: rem(12);
    }
  }
}
